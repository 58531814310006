import React from 'react';

const Input = ({name = "", placeholder = "", onChange = null, id = "", value = "", type = "text", disabled = false, validator = null, validations = "", className = ""}) => {
    return (
        <React.Fragment>
            <div className={className}>
                <input placeholder={placeholder} onChange={onChange} value={value} disabled={disabled} name={name} id={id}
                       type={type}/>
                {validator.message(name, value, validations)}
            </div>
        </React.Fragment>
    );
};

export default Input;