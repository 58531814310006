import axios from "axios";

let language = localStorage.getItem('language');

const { REACT_APP_WEB_API_URL: web_api_url } = process.env;
axios.defaults.baseURL = web_api_url;
export default {
  get: axios.get,
  post: axios.post,
  language,
};
