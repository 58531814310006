export const GET_LANGUAGE = 'GET_LANGUAGE'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

const getLanguageSuccess = (language) => {
  return {
    type: GET_LANGUAGE,
    language,
  }
}

export const changeLanguageSuccess = (language) => {
  console.log(language)
  return {
    type: CHANGE_LANGUAGE,
    language,
  }
}

export const getLanguage = () => {
  return (dispatch) => {
    const language = localStorage.getItem('language')
    dispatch(getLanguageSuccess(language))
  }
}

export const changeLanguage = (language) => {
  return (dispatch) => {
    console.log(language, 'mrenda')
    // localStorage.setItem('language', language)
    dispatch(changeLanguageSuccess(language))
  }
}
