import React, { Component } from 'react'
import logo from '../../assets/img/logo2.svg'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { ChangeLanguage, ChangeLanguageMobile } from '../../locales/language'
import instagramIcon from '../../assets/img/instagram.svg'
import logoMobile from '../../assets/img/mobile-logo.svg'
import { header_titles } from '../../locales/translation'
import Switch from '../../common/form/SwitchButton/SwitchButton'
import { withTranslation } from 'react-i18next'

class NavMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      toggleMenu: false,
      navActive: false,
      showSticky: true,
      scrollPos: 0,
      language: 'en',
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  onToggleMenu = () => {
    const { toggleMenu } = this.state
    this.setState({
      toggleMenu: !toggleMenu,
    })
    if (toggleMenu) {
      document.body.classList.remove('nav-active')
    } else {
      document.body.classList.add('nav-active')
    }
  }

  onScreenResize = () => {
    const screenWidth = document.body.clientWidth
    if (screenWidth > 1000) {
      document.body.classList.remove('nav-active')
      this.setState({
        toggleMenu: false,
      })
    }
  }

  removeClassActive = () => {
    const { toggleMenu } = this.state
    this.setState({
      toggleMenu: !toggleMenu,
    })
    document.body.classList.remove('nav-active')
  }

  clickLinkHandle = (e) => {
    if (e && e.target.getAttribute('href') !== this.props.location.pathname) {
      this.removeClassActive()
    }

    if (e === undefined && this.props.location.pathname !== '/') {
      this.removeClassActive()
    }

    if (e === undefined && this.props.location.pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    if (e && e.target.getAttribute('href') === this.props.location.pathname) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.onScreenResize)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.onScreenResize)
  }

  handleScroll() {
    const { scrollPos } = this.state
    this.setState({
      scrollPos: document.body.getBoundingClientRect().top,
      show: document.body.getBoundingClientRect().top > scrollPos,
      navActive: scrollPos < -100,
      showSticky: scrollPos <= 50 && scrollPos >= -50,
    })
  }

  onLogoClick = () => {
    this.props.history.push('/')
    document.body.classList.remove('white-menu')
    sessionStorage.setItem('scrollPos', 0)
    document.getElementById('navActiveRem').classList.remove('active')

    if (this.props.location.pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  scroll = () => {
    this.removeClassActive()
    setTimeout(() => {
      document.getElementById('footer').scrollIntoView({ behavior: 'smooth' })
    }, 500)
  }

  backdrop = () => {
    this.setState({
      toggleMenu: false,
    })
    document.body.classList.remove('nav-active')
  }

  render() {
    const { toggleMenu, navActive, show, showSticky } = this.state
    let active = false
    let active1 = false
    let blog = false
    let career = false
    let contact = false
    const { t } = this.props

    if (this.props.location.pathname.includes('aboutus')) {
      active = true
    }
    if (this.props.location.pathname.includes('works')) {
      active1 = true
    }
    if (this.props.location.pathname.includes('blogs')) {
      blog = true
    }
    if (this.props.location.pathname.includes('career')) {
      career = true
    }
    if (this.props.location.pathname.includes('contact')) {
      contact = true
    }

    return (
      <React.Fragment>
        <div
          id="navActiveRem"
          className={`nav-menu ${navActive ? 'active' : ''} ${
            show ? '' : 'hide'
          } ${showSticky ? 'show-sticky' : ''}`}
        >
          <div className={`container-fluid`}>
            <div className={`container-limit extra-limit`}>
              <div className={`container`}>
                <div className={`nav-menu_ `}>
                  <div
                    onClick={this.onLogoClick}
                    className={`logo wow fadeInDown`}
                    data-wow-duration="0.5s"
                    data-wow-delay="0.1s"
                  >
                    <img src={logo} alt="" />
                  </div>
                  <div
                    className={`showButton ${toggleMenu ? 'active' : ''}`}
                    onClick={this.onToggleMenu}
                  />
                  {toggleMenu && (
                    <div
                      onClick={() => this.backdrop()}
                      className="backdrop"
                    ></div>
                  )}
                  <div className={`nav-menu__  ${toggleMenu ? 'active' : ''}`}>
                    <div
                      className={`showButtonClose ${
                        toggleMenu ? 'active' : ''
                      }`}
                      onClick={this.onToggleMenu}
                    ></div>
                    <img
                      onClick={this.onLogoClick}
                      className="logo-mobile"
                      src={logoMobile}
                      alt=""
                    />
                    <div
                      onClick={(e) => this.clickLinkHandle(e)}
                      className={`nav-menu__inside wow fadeInDown ${
                        active ? 'active' : ''
                      }`}
                      data-wow-duration="0.5s"
                      data-wow-delay="0.1s"
                    >
                      <Link
                        onClick={() => sessionStorage.setItem('scrollPos', 0)}
                        to="/aboutus"
                      >
                        {t('company')}
                        <div className={`helper-div`}></div>
                      </Link>
                    </div>
                    <div
                      onClick={(e) => this.clickLinkHandle(e)}
                      className={`nav-menu__inside wow fadeInDown ${
                        active1 ? 'active' : ''
                      }`}
                      data-wow-duration="0.5s"
                      data-wow-delay="0.1s"
                    >
                      <Link
                        onClick={() => sessionStorage.setItem('scrollPos', 0)}
                        to="/works"
                      >
                        {t('works')}
                        <div className={`helper-div`}></div>
                      </Link>
                    </div>
                    <div
                      onClick={(e) => this.clickLinkHandle(e)}
                      className={`nav-menu__inside wow fadeInDown ${
                        blog ? 'active' : ''
                      }`}
                      data-wow-duration="0.5s"
                      data-wow-delay="0.1s"
                    >
                      <Link
                        onClick={() => sessionStorage.setItem('scrollPos', 0)}
                        to="/blogs"
                      >
                        {t('blog')}

                        <div className={`helper-div`}></div>
                      </Link>
                    </div>
                    <div
                      onClick={(e) => this.clickLinkHandle(e)}
                      className={`nav-menu__inside wow fadeInDown ${
                        career ? 'active' : ''
                      }`}
                      data-wow-duration="0.5s"
                      data-wow-delay="0.1s"
                    >
                      <Link
                        onClick={() => sessionStorage.setItem('scrollPos', 0)}
                        to="/career"
                      >
                        {t('capitalCareer')}

                        <div className={`helper-div`}></div>
                      </Link>
                    </div>
                    <div
                      onClick={(e) => this.clickLinkHandle(e)}
                      className={`nav-menu__inside wow fadeInDown ${
                        contact ? 'active' : ''
                      }`}
                      data-wow-duration="0.5s"
                      data-wow-delay="0.1s"
                    >
                      <Link to="/contact">
                        {t('contact')}
                        <div className={`helper-div`}></div>
                      </Link>
                    </div>
                    <div
                      style={{ marginTop: 30 }}
                      className={`nav-menu__inside language__ wow fadeInDown data-wow-duration="0.5s" data-wow-delay="0.1s"`}
                    >
                      <div className="ab">
                        <Switch />
                      </div>
                    </div>
                    <div className="changeLanguageMobile">
                      <Switch />
                    </div>
                    <div className={`social-nav pleft15`}>
                      <a
                        href="https://www.facebook.com/tframelabs"
                        target="_blank"
                        className={`wow fadeInUp`}
                        data-wow-duration="0.5s"
                        data-wow-delay="0.8s"
                        rel="noopener noreferrer"
                      >
                        <img src="./assets/img/facebook-icon.svg" alt="" />
                      </a>
                      <a
                        href="https://www.instagram.com/techframelabs"
                        target="_blank"
                        className={`wow fadeInUp`}
                        data-wow-duration="0.5s"
                        data-wow-delay="0.9s"
                        rel="noopener noreferrer"
                      >
                        <img src={instagramIcon} alt="" />
                      </a>
                      <a
                        href="https://www.linkedin.com/company/techframelabs"
                        target="_blank"
                        className={`wow fadeInUp`}
                        data-wow-duration="0.5s"
                        data-wow-delay="1s"
                        rel="noopener noreferrer"
                      >
                        <img src="./assets/img/linkedin-icon.svg" alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation()(NavMenu))
