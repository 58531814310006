import http from "./httpService";


const multipartHeader = {
  headers: {
    "content-type": "multipart/form-data",
  },
};

export function getTeam() {
  return http.get(`team`);
}

export function getProjects(language) {
  return http.get(`projects/${language}`);
}

export function getProjectByTitle(title) {
  return http.get(`project/${title}`);
}

export function getBlogByTitle(title) {
  return http.get(`blog/${title}`);
}

export function getJobByTitle(title) {
  return http.get(`job/${title}`);
}

export function getBlogs(language) {
  return http.get(`blogs/${language}`);
}

export function getJobs(language) {
  return http.get(`jobs/${language}`);
}

export function getPartners(language) {
  return http.get(`partners`);
}

export function getClients(language) {
  return http.get(`clients/${language}`);
}

export function getTestimonials(language) {
  return http.get(`client-testimonials/${language}`);
}

export function getProgrammingLanguages() {
  return http.get(`programming-languages`);
}

export function getTools() {
  return http.get(`tools`);
}

export function postContactUs(data) {
  return http.post(`contact`, data);
}

export function postApply(data) {
  let form_data = new FormData();
  form_data.append("first_name", data.first_name);
  form_data.append("last_name", data.last_name);
  form_data.append("email", data.email);
  form_data.append("phone", data.phone);
  form_data.append("job_id", data.job_id);
  form_data.append("image", data.image, data.image.name);
  return http.post(`apply`, form_data, multipartHeader);
}

export default {
  getTeam,
  getBlogs,
  getClients,
  getProjects,
  getJobs,
  getPartners,
  getTestimonials,
  getProjectByTitle,
  getProgrammingLanguages,
  getTools,
  postContactUs,
  getJobByTitle,
  postApply,
  getBlogByTitle,
};
