import React, { Component } from 'react'
import lines from '../../assets/img/lines.svg'
import { Link, withRouter } from 'react-router-dom'
import apiService from '../../services/apiService'
// import {
//   home_second_section_title,
//   home_second_section_description,
//   home_second_section_button_text,
// } from '../../locales/translation'
import Loader from '../loader/loader'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

class Projects extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: '',
      image: '',
      description: '',
      projects: [],
      classActive: null,
      hoverDiv: false,
      loading: false,
    }
  }

  componentDidMount() {
    this.setState({
      loading: true,
    })
    if (this.props.language) {
      apiService.getProjects(this.props.language).then(({ data: response }) => {
        if (response[0]) {
          this.onProjectChange(response[0], 0)
        }
        this.setState({
          projects: response,
          loading: false,
        })
        const scrollPos = sessionStorage.getItem('scrollPos')
        if (scrollPos) {
          window.scroll({
            top: scrollPos,
            behavior: 'smooth',
          })
        }
      })
    }
  }

  onProjectChange = (project, index) => {
    this.setState({
      image: project.image_cover,
      description: project.description,
      classActive: index,
      title: project.title,
    })
  }

  goTo = () => {
    const { title } = this.state
    this.props.history.push(`/work/${title}`)
  }

  toggleClassHover = (bool) => {
    this.setState({
      hoverDiv: bool,
    })
  }

  render() {
    const { projects } = this.state
    const { t } = this.props
    return (
      <React.Fragment>
        <Loader loading={this.state.loading} />
        <div className="container noPaddingProjects">
          <div className={`projects_section`}>
            <div
              className={`second-section_title   wow fadeInUp noPleft padding15Horizontal`}
              data-wow-duration="0.5s"
              data-wow-delay="0.5s"
            >
              <img src={lines} alt="" />
              <span>{t('homeSecondSectionTitle')}</span>
            </div>
            <div className={`second-section-content  noPleft`}>
              <div className={`grid padding15Horizontal`}>
                <div className={`grid-2 grid-1 inside_grid`}>
                  <div className={`inside_grid_`}>
                    <p
                      style={{
                        color: '#2D2D40',
                        fontWeight: 'lighter',
                        lineHeight: '24px',
                        fontSize: '18px',
                      }}
                      className={`wow fadeInUp sub_title`}
                      data-wow-duration="0.5s"
                      data-wow-delay="0.5s"
                    >
                      {t('homeSecondSectionDiscription')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="projects-wrapper">
                <div
                  style={{ marginBottom: 20 }}
                  className="grid projects-container projects-container-big grid-container left-aligned"
                >
                  {projects &&
                    projects.reverse().map((project, index) => (
                      <div
                        key={index}
                        className="grid-2 grid-1 project-item-wrapper"
                      >
                        <div className="project-item">
                          <div
                            className="project-image"
                            style={{
                              backgroundImage: `url(${project.image})`,
                            }}
                          >
                            <div
                              onClick={() =>
                                this.props.history.push(
                                  `/work/${project.title}`,
                                )
                              }
                              className="projectHoverLayer"
                            >
                              <div className="hoverFillDiv">
                                <h1 className="projectHoverText">
                                  {project.title}
                                </h1>
                                <div className="fillDivBlue"></div>
                                <div className="underLineProject"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="padding15Horizontal">
                  <Link className="see-more-btn hoverUp up" to="/works">
                    {t('homeSecondSectionButtonText')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return { language: state.language.language }
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, null)(Projects)),
)
