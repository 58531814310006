import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import './assets/css/App.css'
import './assets/css/user_layout.css'
import * as serviceWorker from './serviceWorker'
import './assets/css/App.css'
import { BrowserRouter } from 'react-router-dom'
import JavascriptTimeAgo from 'javascript-time-ago'
import { Provider } from 'react-redux'
import en from 'javascript-time-ago/locale/en'
import { store } from './store/storeConfig'
import './i18n'

JavascriptTimeAgo.addLocale(en)

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root'),
)

serviceWorker.unregister()
