import React from "react";
import FirstSection from "./firstSection";
import SecondSection from "./secondSection";

const Home = () => {

  return (
    <React.Fragment>
      <div className={`first-section vh90`}>
        <div className={`bgImage`}></div>
        <div className={`first-container`}>
          <div className={`second-container extra-container`}>
            <div className={`container`}>
              <FirstSection />
            </div>
          </div>
        </div>
      </div>
      <SecondSection currentPage={`Home`} />
    </React.Fragment>
  );
};

export default Home;
