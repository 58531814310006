import React from 'react';

const TextArea = ({name = "", placeholder = "", onChange = null, id = "", value = "", type = "text", disabled = false, validator = null, validations = ""}) => {
    return (
        <React.Fragment>
            <textarea placeholder={placeholder} onChange={onChange} disabled={disabled} name={name} id={id} value={value}
            />
            {validator.message(name, value, validations)}
        </React.Fragment>
    );
};

export default TextArea;