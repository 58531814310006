import React, { Component } from 'react'
// import lines from "../../assets/img/lines.svg";
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import ClientBox from './clientBox'
// import { withTranslation } from 'react-i18next'

import {
  home_final_section_title,
  home_final_section_description,
} from '../../locales/translation'

class Clients extends Component {
  render() {
    // const { t } = this.props

    return (
      <React.Fragment>
        <div className={`clients_section`}>
          {/* <div
            className={`second-section_title testimonials  wow fadeInUp`}
            data-wow-duration="0.5s"
            data-wow-delay="0.5s"
          >
            <img src={lines} alt="" />
            <span>{home_final_section_title}</span>
          </div>
          <div className={`second-section-content `}>
            <div className={`grid`}>
              <div
                className={`grid-1 inside_grid wow fadeInUp`}
                data-wow-duration="0.5s"
                data-wow-delay="0.7s"
              >
                <div className={`inside_grid_`}>
                  <p
                    style={{
                      color: '#2D2D40',
                      fontWeight: 'lighter',
                      lineHeight: '24px',
                      fontSize: '18px',
                    }}
                    className="sub_title"
                  >
                    {t('homeFinalSectionDescription')}
                    <br />
                    {home_final_section_description[1]}
                  </p>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <ClientBox />
      </React.Fragment>
    )
  }
}

export default Clients
