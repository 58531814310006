import React, { Component } from 'react'
import lines from '../../assets/img/lines.svg'
import {
  carrer_final_section_title,
  benefits_12,
} from '../../locales/translation'
import { withTranslation } from 'react-i18next'

class Benefits extends Component {
  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        <div className={`benefits`}>
          <div
            style={{
              marginBottom: 0,
            }}
            className={`big-title pleft15 color whoWeAreDivTitle noPleft`}
          >
            <img className="linesMobile" src={lines} alt="" />
            <div>
              <h2
                className="h2Tags"
                style={{
                  marginLeft: 10,
                  lineHeight: 1.4,
                  color: '#000347',
                  textTransform: 'uppercase',
                }}
              >
                {t('perks')}{' '}
              </h2>
              <h2
                className="h2Tags"
                style={{
                  marginLeft: 10,
                  lineHeight: 1.4,
                  color: '#000347',
                  textTransform: 'uppercase',
                }}
              >
                {t('benefits')}
              </h2>
            </div>
          </div>
          <div className={`second-section-content pleft15`}>
            <div className={`grid pleft15`}>
              <div className={`grid-2  inside_grid`}>
                <div className={`inside_grid__`}></div>
              </div>
            </div>
          </div>
          <div className={`grid pleft15 mTop50 noPleft`}>
            <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/beach.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{t('paidHolidays')}</span>
                </div>
              </div>
            </div>
            {/* <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/pain.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{(t'paidSickDays')}</span>
                </div>
              </div>
            </div> */}
            {/* <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/tshirt.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{t('casualDress')}</span>
                </div>
              </div>
            </div> */}
            <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/cup.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{t('freeCoffee')}</span>
                </div>
              </div>
            </div>
            {/* <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/fruit copy.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{t('SomeMealsProvided')}</span>
                </div>
              </div>
            </div> */}
            <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/bowling.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{t('companyOutings')}</span>
                </div>
              </div>
            </div>
            <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/apple.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{t('stockedKitchen')}</span>
                </div>
              </div>
            </div>
            <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/contract.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{t('jobTraining')}</span>
                </div>
              </div>
            </div>
            {/* <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/browser.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{t('lunchLearns')}</span>
                </div>
              </div>
            </div> */}
            <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/controller.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{t('gameRoom')}</span>
                </div>
              </div>
            </div>
            <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/ping-pong.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{t('recreationalClubs')}</span>
                </div>
              </div>
            </div>
            <div className={`grid-4 grid-2  benefits_`}>
              <div className={`benefits__`}>
                <div className={`benefits_left`}>
                  <img src="/assets/img/group8.svg" alt="" />
                </div>
                <div className={`benefits_right`}>
                  <span>{t('diversityProgram')}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(Benefits)
