import React, { Component } from 'react'
import lines from '../../assets/img/lines.svg'
import { Link } from 'react-router-dom'
import apiService from '../../services/apiService'
import ReactTimeAgo from 'react-time-ago'
import { carrer_first_section_title } from '../../locales/translation'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class OpenPositions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      jobs: [],
    }
  }

  componentDidMount() {
    if (this.props.language) {
      apiService.getJobs(this.props.language).then(({ data: response }) => {
        this.setState({
          jobs: response,
        })
        this.props.loading()
      })
    }
  }

  render() {
    const { jobs } = this.state
    const { t } = this.props
    return (
      <React.Fragment>
        {jobs[0] && (
          <div className={`open_position`}>
            <div
              className={`big-title pleft15 color mBottom50 whoWeAreDivTitle`}
            >
              <img className="linesMobile" src={lines} alt="" />
              <div>
                <span className="h2Tags">
                  {t('open')}
                  <br />
                  {t('positions')}
                </span>
              </div>
            </div>
            <div className={`grid `}>
              {jobs.map((element, index) => (
                <div
                  key={index}
                  className={`grid-4 grid-3 grid-2 grid-1 open_position_`}
                >
                  <Link
                    className="link-wrapp"
                    to={`/job/${
                      this.props.language === 'en'
                        ? element.title
                        : element.de_title
                    }`}
                  >
                    <div className={`open_position__`}>
                      <div className={`open_position__right`}>
                        <div
                          style={{ height: 80 }}
                          className={`open_position__right_top`}
                        >
                          <Link
                            to={`/job/${
                              this.props.language === 'en'
                                ? element.title
                                : element.de_title
                            }`}
                          >{`${
                            this.props.language === 'en'
                              ? element.title
                              : element.de_title
                          }`}</Link>
                        </div>
                        <div className={`open_position__img`}></div>
                        <div className={`open_position__right_bottom`}>
                          <div className={`open_position__right_bottom_`}>
                            <img src="./assets/img/location.svg" alt="" />
                            <span>{element.location}</span>
                          </div>
                          {/* <div
                            className={`open_position__right_bottom_ time-ago`}
                          >
                            <img src="./assets/img/clock.svg" alt="" />
                            <span>
                              <ReactTimeAgo
                                date={new Date(
                                  element.date_posted
                                ).toLocaleString("en-US", {
                                  timeZone: "Etc/UTC",
                                })}
                              />
                            </span>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return { language: state.language.language }
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, null)(OpenPositions)),
)
