import React, { Component } from 'react'
import lines from '../../assets/img/lines.svg'
import {
  company_fifth_section_title,
  company_fifth_section_people,
} from '../../locales/translation'
import { withTranslation } from 'react-i18next'

class OurTeam extends Component {
  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <div className={`our-team marginleftmobile`}>
          <div className={`first-container`}>
            <div className={`second-container extra-container`}>
              <div className={`container`}>
                <div
                  style={{ marginBottom: 20 }}
                  className={`whoWeAreDivTitle padleftCom`}
                >
                  <img className="linesMobile" src={lines} alt="" />

                  <div>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        fontSize: 30,
                        color: '#000347',
                      }}
                    >
                      {t('our')}{' '}
                    </h2>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        fontSize: 30,
                        color: '#000347',
                      }}
                    >
                      {t('team')}
                    </h2>
                  </div>
                </div>
                <div className={`grid pleft15`}>
                  <div className={`grid-2 grid-1 inside_grid`}>
                    <div className={`inside_grid__`}></div>
                  </div>
                </div>
                <div
                  className={`team-pos`}
                  style={{ backgroundColor: '#000047' }}
                >
                  <div className={`team-pos-desc`}>
                    <span className={`number`}>+30</span>
                    <span className={`desc`}>{t('softwareEngineers')}</span>
                  </div>
                  <div className={`team-pos-desc`}>
                    <span className={`number`}>3</span>
                    <span className={`desc`}>{t('qualityAssurance')}</span>
                  </div>
                  <div className={`team-pos-desc`}>
                    <span className={`number`}>3 </span>
                    <span className={`desc`}>{t('financeManagement')}</span>
                  </div>
                  <div className={`team-pos-desc`}>
                    <span className={`number`}>2 </span>
                    <span className={`desc`}>{t('projectManagers')}</span>
                  </div>
                  <div className={`team-pos-desc`}>
                    <span className={`number`}>2 </span>
                    <span className={`desc`}>{t('uiUxDesigners')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
export default withTranslation()(OurTeam)
