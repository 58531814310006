/* eslint-disable max-len */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      Date: 'Date',
    },
  },
  de: {
    translation: {
      Date: 'Datum',
    },
  },
}

const _language = localStorage.getItem('languageVal') || 'de'
export const langVar = localStorage.getItem('languageVal')

export const resourcesi18n = {
  en: {
    translation: {
      weDo: 'We Do',
      //home_banner_title[0] should change with weDo(DONE)

      digital: 'DIGITAL',
      //home_banner_title[1] should change with digital(DONE)

      transformation: 'TRANSFORMATION',
      //home_banner_title[2] should change with transformation(DONE)

      throughMultiscreen: 'through multiscreen',
      //home_banner_title[3] should change withh throughMultiscreen(DONE)

      smartSolution: 'SMART SOLUTIONS',
      //home_banner_title[4] should change with smartSolution(DONE)

      homeFirstSectionTitle: 'TODAY, EVERYTHING IS',
      //home_first_section_title

      homeFirstSectionTitle1: ' POSSIBLE!',
      //home_first_section_title1

      homeFirstSectionDiscription1:
        'Many of our clients through the years had different needs in digitalization of business activities, processes and increasing efficiency. We build with the most innovative technologies to deliver the smartest solutions that yield better results in day to day operations, and we’re still improving.',
      //home_first_section_description1

      homeFirstSectionDiscription2:
        'Our requirement engineers and planning department is highly skilled in analyzing your operational processes, and by working closely together with your organization, many of those processes become digital - making these processes become more seamless and efficient.',
      //home_first_section_description2

      homeSecondSectionTitle: 'Our recent projects',
      //home_second_section_title

      homeSecondSectionDiscription:
        'View some of the most successful projects and more by our specialists presented in this gallery. We work to make your business start effectively working for you.',
      //home_second_section_description

      homeSecondSectionButtonText: 'All Projects',
      //home_second_section_button_text

      homeThirdSectioTitle: 'Full range of software',
      //home_third_section_title[0] should change with homeThirdSectioTitle

      homeThirdSectioTitle1: 'engineering services',
      //home_third_section_title[1] should change with homeThirdSectioTitle1

      homeThirdSectionButtonText: 'Company',
      //home_third_section_button_text

      homeFinalSectionTitle: 'Clients Testimonials',
      //home_final_section_title

      homeFinalSectionDescription:
        'Tech Frame has offices in Europe, United States and nearshore Center in, Kosovo in order for us to work closely with our clients.',
      //home_final_section_description

      clients: 'Clients',
      //home_final_section_testimonial_left[0] this  should change with client

      projects: 'Projects',
      //home_final_section_testimonial_left[1] this  should change with project

      countries: 'Countries',
      //home_final_section_testimonial_left[2] this should change with countries

      becomeATrue: 'Become a true',
      //company_banner_title[0] this should change with becomeATrue

      inovation: 'inovation',
      //company_banner_title[1] this should change with inovation

      partnerToOurClients: 'partner to our clients',
      //company_banner_title[2] this should change with partnerToOurClients

      companyBannerDescription:
        'We develop software and apps for our international clients, with auxiliary services like digital marketing and call center support. Tech Frame is capable of handling a product at any stage of its life cycle. Whether the client needs a brand new product, designed, developed, branded and rolled out, or they need help with existing products we can handle the task.',
      //company_banner_description

      companyBannerButtonText: 'Company presentation',
      //company_banner_button_text

      who: 'WHO',
      //this company_first_section_title[0] should change with who

      weAre: 'WE ARE',
      //this company_first_section_title[1] should change with whoWeAre

      companyFirstSectionDescription0:
        'Our team of 30+ engineers with different backgrounds, are eager to turn your business activities and processes to to cutting edge software, as well as building your entire organization’s digital ecosystem from the ground up. By analyzing your needs, and planning your digitalization, we can reshape every aspect of a modern enterprise.',
      //this company_first_section_description[0] should change with companyFirstSectionDescription0

      companyFirstSectionDescription1:
        'Tech Frame is a fully focused, digital transformation organization, that aims in leveraging the full extent of the possibilities offered by the most up-to-date technologies, in order bring their impact in a more innovative future.',
      //company_first_section_title[1] this  should change with companyFirstSectionDescription1

      our: 'OUR',
      //company_second_section_title[0]

      services: 'SERVICES',
      //company_second_section_title[1]

      industry: 'INDUSTRY',
      //company_third_section_title[0]

      expertise: 'EXPERTISE',
      //company_third_section_title[1]

      eLearaning: 'E-Learning',
      //company_third_section_cards[0]

      telecomunication: 'Telecomunication',
      //company_third_section_cards[1]

      agriculture: 'Agriculture',
      //company_third_section_cards[2]

      eCommerce: 'E-Commerce',
      //company_third_section_cards[3]

      humanResources: 'Human Resources',
      //company_third_section_cards[4]

      businessProcessAutomation: 'Business process automation',
      //company_third_section_cards[5]

      agileDevelopment: 'Agile Developement',
      //company_fourth_section_title[0] should change with agileDevelopment

      process: 'Process',
      // company_fourth_section_title[1] should change with process

      projectBased: 'Project-Based',
      //company_fourth_section_left_title[0] should change with projectBased

      cooperationMethodology: 'Cooperation Methodology',
      //company_fourth_section_left_title[1] should change with cooperationMethodology

      plan: 'Plan',
      //company_fourth_section_left_cards_titles[0] should change with plan

      develop: 'Develop',
      //company_fourth_section_left_cards_titles[1] should change with develop

      stabilize: 'Stabilize',
      //company_fourth_section_left_cards_titles[2] should change with stablize

      deliver: 'Deliver',
      //company_fourth_section_left_cards_titles[3] should change with deliver

      clientProvide:
        'Client provide us with specification document, brief or mock-ups. We provide planning, project budget with milestones along with acceptance criteria. After agreement is established we initiate development.',
      //company_fourth_section_left_cards_descriptions[0] should change with clientProvide

      theTeamIsFormed:
        'The team is formed and assigned with Project Manager/Team Lead. The project is Carried out in a set of iterations and collaboration Process is coordinated with the client. While our Team carries out coding and integration, regular Builds are shared with the client.',
      //company_fourth_section_left_cards_descriptions[1] should change with theTeamIsFormed

      tFrameQaTeam:
        'TECH FRAME QA team conducts testing of the product and issues correspondent test reports. Project Team proceeds with the project stabilization based on the QA documentation and provides the client with the release candidate.',
      // company_fourth_section_left_cards_descriptions[2] should change with tFrameQaTeam

      theClientIsDelivered:
        'The client is delivered with the final product which is consequently verified according to the acceptance criteria. All the source code is passed to the client and the product is deployed to the client’s environment.',
      //company_fourth_section_left_cards_descriptions[3] should change with theClientIsDelivered

      companyFourthSectionRightTitle: 'Agile Methodology',

      goalDefinition: 'Goal Definition',
      //company_fourth_section_right_cards_titles[0] should change with goalDefinition

      sprintPlanning: 'Sprint Planning',
      //company_fourth_section_right_cards_titles[1] should change with sprintPlanning

      development: 'Development',
      //company_fourth_section_right_cards_titles[2] should change with development

      acceptanceTesting: 'Acceptance Testing',
      //company_fourth_section_right_cards_titles[3] should change with acceptanceTesting

      initialScope:
        'Initial scope is investigated to align client’s expectations on deadlines, milestones, deliverables and team composition.',
      //company_fourth_section_right_cards_descriptions[0] should change with initialScope

      projectManagerProvides:
        'Project Manager provides short term planning, setting up timeframe of the sprints and choosing tasks from the backlog to include in the current iteration.',
      //company_fourth_section_right_cards_descriptions[1] should change with projectManagerProvides

      teamCarries:
        'Team carries out implementation on the tasks, included in the current sprint along with developer testing.',
      //company_fourth_section_right_cards_descriptions[2] should change with teamCarries

      basedOnAcceptance:
        'Based on the acceptance criteria, after finalizing all tasks, chosen for the iteration demo/gloomung meeting is held with the client to discuss current results and simultaneously plan next sprint.',
      //company_fourth_section_right_cards_descriptions[3] should change with basedOnAcceptance

      //companyFifthSectionTitle[0]: we have one OUR in this Section i18n
      team: 'TEAM',
      // company_fifth_section_title[1] should change with team

      softwareEngineers: 'SOFTWARE ENGINEERS',
      //company_fifth_section_people[0] should change with softwareEngineers

      qualityAssurance: 'QUALITY ASSURANCE',
      //company_fifth_section_people[1] should change with qualityAssurance

      financeManagement: 'FINANCE & MANAGEMENT',
      //company_fifth_section_people[2] should change with financeManagement

      projectManagers: 'PROJECT MANAGERS',
      //company_fifth_section_people[3] should change with projectManagers

      uiUxDesigners: 'UI/UX DESIGNERS',
      //company_fifth_section_people[4] should change with uiUxDesigners

      techStack: 'TECH STACK',
      //company_final_section_title1 should change with techStack

      infrastructure: 'INFRASTRUCTURE',
      //company_final_section_title2[0] should change with infrastructure

      asCode: 'AS CODE',
      //company_final_section_title2[1] should change with asCode

      capitalProjects: 'PROJECTS',
      //works_title should change with capitalProjects

      worksDescription:
        'Rapid changes in consumer trends have caused the need for innovation and digital transformation for many industries worldwide.',
      //works_description1 should change with workDescription

      worksDescription1:
        'View some of the most successful projects and more by our specialists presented in this gallery. We work to make your business start effectively working for you.',
      //home_second_section_description should change with workDescription1

      worksDescription2:
        'We as Techframe are constantly following the technology trends and innovations, and through a professional consulting team, we prepare and present you with a fully customized solution that follows the best practices of digital transformation for your company, organization. Some of the selected projects that we have done are as follows:',
      //works_description2 should change with workDescription2

      worksDescription2Mobile:
        'Some of the selected projects that we have done are as follows:',
      //works_description2Mobile

      insideWorkNext: 'Next Project',
      //inside_work_next

      insideWorkPrev: 'Prev Project',
      //inside_work_prev

      date: 'Date',
      //inside_work_details[0] should change with date

      client: 'Client',
      //inside_work_details[1] should change with client

      category: 'Category',
      //inside_work_details[2] should change with category

      latestBlogs: 'LATEST BLOGS & NEWS',
      //blog_title

      blogDescription: 'Get informed with lates Blog & News.',
      //blog_description

      blogReadMore: 'read more',
      //blog_read_more

      blogTags: 'Tags',
      //inside_blog_tags

      realtedBlogs: 'Related Blogs',
      //inside_blog_related_title

      career: 'Career',
      //carrer_banner_title_small

      freshBlood: 'FRESH BLOOD IS',
      //carrer_banner_title_big[0] should change with freshBlood

      always: 'ALWAYS',
      //carrer_banner_title_big[1] should change with always,

      welcomed: 'WELCOMED',
      //carrer_banner_title_big[2] should change with welcomed

      carrerBannerTitleDescription:
        'We are growing day by day, that’s why we need you to contribute in our brainstorming meetings and solve our problems.',
      //carrer_banner_title_description

      open: 'Open',
      //carrer_first_section_title[0] should change with open

      positions: 'Positions',
      //carrer_first_section_title[1] should change with Positions

      perks: 'Perks &',
      //carrer_final_section_title[0] should change with perks

      benefits: 'Benefits',
      //carrer_final_section_title[1] should change with benefits

      apply: 'Apply',
      //carrer_apply

      applyNow: 'Apply Now',
      //carrer_apply_title

      firstName: 'First Name',
      //carrer_apply_name

      lastName: 'Last Name',
      //carrer_apply_sur_name

      email: 'Email',
      //carrer_apply_email

      phoneNumber: 'Phone Number',
      //carrer_apply_number

      yourCv: 'Your Cv',
      //carrer_apply_cv

      submitApplication: 'Submit Application',
      //carrer_apply_submit

      chooseFile: 'Choose File',
      //carrer_apply_choose

      letsStart: 'Let’s start',
      //contact_title_big[0] should change with letsStart

      working: 'working',
      //contact_title_big[1] should change with letsStart

      together: 'together',
      //contact_title_big[2] should change with letsStart

      needsDiscuss: 'Let’s discuss your needs!',
      //contact_title_small

      fullNameMustBe: 'Full Name must be longer than 2 letters!',
      //contact_errors[0] should change with fullNameMustBe

      subjectMustBe: 'Subject must be longer than 5 letters!',
      //contact_errors[1] should change with subjectMustBe

      enterValidEmail: 'Please enter a valid email!',
      //contact_errors[2] should change with enterValidEmail

      messageMustbe: 'Message must be longer than 10 letters!',
      //contact_errors[3] should change with messageMustbe

      fieldsAreNotValid: 'Fields are not valid !',
      //contact_errors[4] should change with fieldsAreNotValid

      sendMessage: 'Send Message',
      //contact_button_text

      generalInquiries: 'General Inquiries',
      //contact_contact_us_title

      location: 'Location',
      //contact_location_title

      contactLocation0: 'TECH FRAME LLC Nearshore center',

      contactLocation1: 'Agim Ramadani,',
      //contact_location_data[0] should change with contactLocation1

      contactLocation2: 'Bregu i Diellit 10000,',
      //contact_location_data[1] should change with contactLocation1

      contactLocation3: 'Pristina, Kosovo.',
      //contact_location_data[2] should change with contactLocation1

      contactLocationDe1: 'TECH FRAME Deutschland GmbH,',
      //contact_location_data[0] should change with contactLocation1

      contactLocationDe2: 'Höhenrainer Straße 5, 81477 München,',
      //contact_location_data[1] should change with contactLocation1

      contactLocationDe3: 'HRB 271429. UID DE349279205.',
      //contact_location_data[2] should change with contactLocation1

      getLocation: 'Get Location',
      //contact_link

      follow: 'Follow',
      //contact_social_title

      home: 'HOME',
      //header_titles[0] should change with home

      company: 'COMPANY',
      //header_titles[1] should change with company

      works: 'WORKS',
      //header_titles[2] should change with works

      blog: 'BLOG',
      //header_titles[3] should change with blog

      capitalCareer: 'CAREER',
      //header_titles[4] should change with career

      contact: 'CONTACT',
      //header_titles[5] should change with contact

      footerTitle: 'Let’s start working together',
      //footer_title

      digitalization: 'Digitalization',
      //cards_6[0] should change with digitalization

      uiUxDesign: 'UI/UX Design & Branding Services',
      //cards_6[1] should change with uiUxDesign

      webAppDev: 'Web Application / Development',
      //cards_6[2] should change with webAppDev

      mobileDev: 'Mobile Development',
      //cards_6[3] should change with mobileDev

      qaManual: 'QA Manual & Automation',
      //cards_6[4] should change with qaManual

      techSupport: 'Tech Support',
      //cards_6[5] should change with techSupport

      cardsDescription1:
        'Rapid change of lifestyle trends has forced the automation of many communication and collaboration processes thus simplifying everything we do in our daily lives and works. From online orders and payments, up to production, distribution and client support and engagement. Our digital consultants’ team has developed many transformation projects for multiple companies in a wide spectrum of digitalization that has simplified many company operations, collaborations, and the way they do business.',
      //cards_description_6[0] should change with cardsDescription1

      cardsDescription2:
        'Digitalization has transformed not just objective operations, but also many industries that were considered more subjective and more engaged in matters of feel, see, believe, and trust. Brands that were developed in many physical forms in the past that required multiple physical engagements, now have transformed to a client’s online journey and engagement where the brand is build alongside the clients experience and recommendation.',
      //cards_description_6[1] should change with cardsDescription2

      cardsDescription3:
        'Staring from two-sided to more complex and multi-sided platforms and for different purposes including: matchmaking, employee collaboration, project management, procurement, academic and industrial level applications that have transformed the process of hundreds of SME’s and Corporations globally.',
      //cards_description_6[2] should change with cardsDescription3

      cardsDescription4:
        'Having into consideration that 90% of the time spend on mobile from mobile users is spend on applications, and that mobile form of buying and collaboration is mostly preferred by the users, many companies are adapting this fact and turning their offers into mobile version of communicating.',
      //cards_description_6[3] should change with cardsDescription4

      cardsDescription5:
        "In order to streamline deviations, corrective and preventive actions (CAPAs), and other processes, the use of digital tools like separate software is used to perform these tasks. Digital tools built on the same platform provide further visibility into a company's quality assurance.",
      //cards_description_6[4] should change with cardsDescription5

      cardsDescription6:
        "Our company's Tech Support Officers are willing to assist and resolve your company's technical issues, provide timely customer feedback, support the roll-out of new applications, talk to customers directly, as well as create written documentation, with excellent written and verbal communication.",
      //cards_description_6[5] should change with cardsDescription6

      paidHolidays: 'Paid Holidays',
      //benefits_12[0] should be change with

      paidSickDays: 'Paid sick days',
      //benefits_12[1] should be change with

      casualDress: 'Casual dress',
      //benefits_12[2] should be change with

      freeCoffee: 'Free Coffee',
      //benefits_12[3] should be change with

      SomeMealsProvided: 'Some meals provided',
      //benefits_12[4] should be change with

      CompanyOutings: 'Company Outings',
      //benefits_12[5] should be change with

      stockedKitchen: 'Stocked kitchen',
      //benefits_12[6] should be change with

      jobTraining: 'Job Training & Conferences',
      //benefits_12[7] should be change with

      lunchLearns: 'Lunch & Learns',
      //benefits_12[8] should be change with

      gameRoom: 'Game Room',
      //benefits_12[9] should be change with

      recreationalClubs: 'Recreational clubs',
      //benefits_12[10] should be change with

      diversityProgram: 'Diversity Program',
      //benefits_12[11] should be change with

      message: 'Message',
      //placeholders_4[3]

      subject: 'Subject',
      //placeholders_4[4]

      fullName: 'Full Name',
      //placeholders_4[5]

      //bigSquareSquares
      eLearaningDsc1:
        'The scale, scope and power of digital transformation as evidenced by phenomena such as connectivity, platforms, algorithmic power, and big data is vigorous. The strong interconnectedness and interdependence between technologies and markets are key features of this transformation. Speaking of digital transformations, technology have facilitated the operations of academic institutions also known as EdTech where the learning process has transformed from in-class to online learning. It has transformed and developed a new interaction among users: teachers to teachers, teachers to students, students to students where forms of teaching, examination and evaluation have been adopted online through multi sided platforms, integrated modules, mobile learning, team projects, testing and grading, etc.',

      telecomunicationDsc2:
        'Technology advancement has cause the integration of all media including: voice, audio, video, and data over a single common network. Therefore, telecommunication companies are focusing on providing solutions on all media simultaneously rather than specializing in a particular type. As a result of our clients’ request, we as TechFrame have the solution for the best practices of communication technology that can help companies uplift their performance and perform better at their daily tasks.',

      agricultureDsc3:
        'Digital technologies—including the Internet, mobile technologies and devices, data analytics, artificial intelligence, digitally-delivered services and apps—are changing agriculture and the food system. Some examples where digitalization has shaped the agri-food value chain include: farm machinery automation allows fine-tuning of inputs and reduces demand for manual labour; remote satellite data and in-situ sensors improve the accuracy and reduce the cost of monitoring crop growth and quality of land or water; traceability technologies and digital logistics services offer the potential to streamline agri-food supply chains, while also providing trusted information for consumers. At Techframe, we adopt the best practices and innovations that can help agriculture companies increase their values, improve operation, increase production, reduce costs and provide real time data and analytics.',

      eCommerceDsc4:
        'Electronic Commerce has transformed the way the trades are developed globally. Instead of physically visiting the stores and malls, now buyers are presented with different forms of customer services and the purchasing process has never been this simply and easy. No matter what business model the companies have, there are limitless solutions for every business model and business transaction, whether it is business-to-business (B2B), business-to-consumer (B2C), consumer-to-consumer or consumer-to-business. Techframes team is specialized in e-commerce platforms, and can offer outstanding solutions for your business through variety of trade options online.',

      humanResourcesDsc5:
        'Human Resource digitalization involves the conversion of physical assets into a digital format to optimize existing processes and improve them dramatically. Today’s HR transformations focus on HR self-service departments, which manifest in two ways: First, if your HR department still uses paperwork, it should be digitized. Digitizing paper employee records in a Human Resources Information System (HRIS) database is a classic example of digitization in HR. Second, if any of your HR work is repetitive, it should be automated. The combination of AI in HR has created a better perspective in organization of shifts, employee evaluation and performance, employee skill upgrade, etc. Based on your business needs and requests, we at Techframe are capable to create systems and integrate multiple departments’ communication thus uplifting performance in multiple dimensions inside the company.',

      businessProcessAutomationDsc6:
        'Business Process Automation, also known as Business Digital Transformation is the use of technology to execute recurring tasks or processes in a business where manual effort can be replaced. BPA minimizes costs, increases efficiency, and streamlines processes like for example purchase orders, employee onboarding. Some benefits of BPA are boost of productivity, transparency, risk management, cost reduction, automation of mundane recurring tasks can create space for tasks that require human effort and judgment.',

      //Done==========================================================================================================
    },
  },
  de: {
    translation: {
      weDo: 'Wir schaffen',
      digital: 'DIGITALEN',
      transformation: 'WANDEL',
      throughMultiscreen: 'durch MULTISCREEN-LÖSUNG',
      smartSolution: 'SMARTE LÖSUNGEN',
      homeFirstSectionTitle: 'HEUTE IST ALLES MÖGLICH!',
      homeFirstSectionTitle1: ' POSSIBLE!',
      irstSectionDiscription1:
        'Viele unserer Kunden hatten in den vergangenen Jahren unterschiedliche Anforderungen bezüglich  der Digitalisierung von täglichen Geschäftsprozessen und der damit verbundenen Steigerung der Effizienz Wir setzen auf innovativste Technologien, um die bestmöglichste und für Ihr Unternehmen passende Lösungen zu liefern mit dem Ziel bessere Ergebnisse im Daily Business zu erzielen.',
      irstSectionDiscription2:
        'Unsere Ingenieure und Planungsabteilung verfügen über Expertise, Erfahrung und Augenmaß, wenn es darum geht, die Prozesse, Methoden oder Standards unserer Kunden zu analysieren und zu verbessern. Durch die enge Zusammenarbeit mit Ihrem Unternehmen werden viele dieser Prozesse digitalisiert, wodurch diese Prozesse nahtloser und effizienter werden.',
      homeSecondSectionTitle: 'Unsere letzten projekte',
      homeSecondSectionDiscription:
        'In unserer Gallerie finden Sie eine Auswahl unserer erfolgreichsten Projekte, die von unseren Expertenteam durchgeführt wurden.Wir arbeiten daran, dass Ihr Unternehmen effektiv für Sie arbeitet.',
      homeSecondSectionButtonText: 'All Projects',
      homeThirdSectioTitle:
        'ALLE BEREICHE VON SOFTWARE-ENGINEERING-DIENSTLEISTUNGE',
      homeThirdSectionButtonText: 'Unser Unternehmen',
      homeFinalSectionTitle: 'Clients Testimonials',
      homeFinalSectionDescription:
        'Tech Frame hat Niederlassungen in Europa, den Vereinigten Staaten und Nearshore Center in, Kosovo, damit wir eng mit unseren Kunden zusammenarbeiten können.',

      clients: 'Kunden', //this home_final_section_testimonial_left[0] should change with client
      projects: 'Projekte', //this home_final_section_testimonial_left[1] should change with project
      countries: 'Kunden', //this home_final_section_testimonial_left[2] should change with countries

      becomeATrue: 'Werden sie ein treuer', //this company_banner_title[0] should change with becomeATrue
      inovation: 'innovationspartner', //this company_banner_title[1] should change with inovation
      partnerToOurClients: 'für unsere kunden', //this company_banner_title[2] should change with partnerToOurClients

      companyBannerDescription:
        'Wir entwickeln Softwares und Apps mit Zusatzleistungenwie digitalem Marketing und Call-Center-Support für unsere internationalen Kunden. Ganz gleich ob der Kunde ein neues Produkt benötigt, das entworfen, entwickelt, gebrandet und eingeführt werden muss oder ein bereits bestehendes Produkt neu vermarkten möchte, Tech Frame weiss, wie man ein Produkt auf dem Markt aussichtsreich platzieren und verkaufen kann.',
      companyBannerButtonText: 'Firmenpräsentation',

      who: 'WER',
      //this company_first_section_title[0] should change with who

      weAre: 'SIND WIR',
      //this company_first_section_title[1] should change with whoWeAre

      companyFirstSectionDescription0:
        'Unser Team besteht aus mehr als 30 Ingenieuren mit unterschiedlichem Hintergrund, die bestrebt sind, Ihre täglichen Geschäftsprozesse mit modernster Software effizienter zu gestalten. Wir unterstützen Sie also das digitale Ökosystem Ihres gesamten Unternehmens von Grund auf neu zu bauen. Durch die Analyse Ihrer Bedürfnisse und die Planung der Digitalisierung können wir Ihr Unternehmen bis ins kleinste Detail modernisierenund neu gestalten.',
      //this company_first_section_title[0] should change with companyFirstSectionDescription0

      companyFirstSectionDescription1:
        'Tech Frame fokusiert sich dabei auf die digitale Transformation, die darauf abzielt, die Möglichkeiten der neuesten Technologien voll auszuschöpfen und um so ihre Wirkung in einer innovativeren Zukunft zu entfalten.',
      //this company_first_section_title[1] should change with companyFirstSectionDescription1

      our: 'UNSER',
      services: 'DIENSTLEISTUNGEN',
      industry: 'BRANCHEN',
      expertise: 'EXPERTISE',
      eLearaning: 'E-Learning',
      telecomunication: 'Telekommunikation',
      agriculture: 'Landwirtschaft',
      eCommerce: 'E-Commerce',
      humanResources: 'Personalmanagement',
      businessProcessAutomation: 'Automatisierung Geschäftsprozesse',

      agileDevelopment: 'AGILER', //company_fourth_section_title[0] should change with agileDevelopment
      process: 'ENTWICKLUNGSPROZESS', // company_fourth_section_title[1] should change with process

      projectBased: 'Projektbasierte', //company_fourth_section_left_title[0] should change with projectBased
      cooperationMethodology: 'Kooperationsmethoden', ///company_fourth_section_left_title[1] should change with cooperationMethodology

      plan: 'Planing', //company_fourth_section_left_cards_titles[0] should change with plan
      develop: 'Entwicklung', //company_fourth_section_left_cards_titles[1] should change with develop
      stabilize: 'Stabilisierung', //company_fourth_section_left_cards_titles[2] should change with stablize
      deliver: 'Lieferung', //company_fourth_section_left_cards_titles[3] should change with deliver

      clientProvide:
        'Der Kunde stellt uns ein detailiertes Dokument, eine Kurzbeschreibung oder ein Geschäftsmodell zur Verfügung. Wir machen uns dann an die Planung, bestimmen das Projektbudget und legen die Meilensteine sowie Akzeptanzkriterien fest. Nach gemeinsamer Einigung, machen wir uns sofort an die Umsetzungdes Projekts.', //company_fourth_section_left_cards_descriptions[0] should change with clientProvide
      theTeamIsFormed:
        'Das Team wird gebildet und einem Projektmanager / Teamleiter zugeteilt. Das Projekt wird in einer Reihe von iterativen Prozessen durchgeführt, welche zusammen mit den Kunden koordiniert werden. Während unser Team die Codierung und Integration durchführt, werden regelmäßige Builds (Prozess des Zusammenfügens von Programmteilen als auch die fertige Software-Version) mit dem Kunden geteilt.', //company_fourth_section_left_cards_descriptions[1] should change with theTeamIsFormed
      tFrameQaTeam:
        'Das Team der Qualitätssicherung von TECH FRAME führt Tests durch und erstellt Testberichte für das entsprechende Projekt. Das Projektteam fährt mit der Projektstabilisierung auf der Grundlage der Dokumentation der Qualitätssicherung  fort und stellt dem Kunden die endgültige Version (release candidate) zur Verfügung.', // company_fourth_section_left_cards_descriptions[2] should change with tFrameQaTeam
      theClientIsDelivered:
        'Der Kunde erhält das Endprodukt, das folglich anhand der Abnahmekriterien überprüft wird. Der gesamte Quellcode wird an den Kunden übergeben und das Produkt wird beim Kunden vor Ort bereitgestellt', //company_fourth_section_left_cards_descriptions[3] should change with theClientIsDelivered

      companyFourthSectionRightTitle: 'Agile Methodik',

      goalDefinition: 'Zieldefinition', //company_fourth_section_right_cards_titles[0] should change with goalDefinition
      sprintPlanning: 'Sprint-Planung', //company_fourth_section_right_cards_titles[1] should change with sprintPlanning
      development: 'Entwicklung', //company_fourth_section_right_cards_titles[2] should change with development
      acceptanceTesting: 'Akzeptanztest', //company_fourth_section_right_cards_titles[3] should change with acceptanceTesting

      initialScope:
        'Der anfängliche Umfang wird erarbeitet, um die Erwartungen des Kunden an Termine, Meilensteine, Ergebnisse und Teamzusammensetzung zu treffen.', //company_fourth_section_right_cards_descriptions[0] should change with initialScope
      projectManagerProvides:
        'Der Projektmanager organisiert eine kurzfristige Planung, legt den Zeitrahmen für die Sprints fest, wählt Aufgaben aus und benennt das von ihm gewünschte Sprint-Ziel und priorisiert die Einträge des Produkt-Backlogs im Sinn dieses Ziels.', //company_fourth_section_right_cards_descriptions[1] should change with projectManagerProvides
      teamCarries:
        'Das Entwicklungsteam führt die Implementierung der im aktuellen Sprint enthaltenen Aufgaben zusammen mit Entwicklertests durch.', //company_fourth_section_right_cards_descriptions[2] should change with teamCarries
      basedOnAcceptance:
        'Basierend auf den zuvor festgelegten Kriterien wird nach Abschluss aller Aufgaben, ein Meeting mit dem Kunden abgehalten, um die aktuellen Ergebnisse zu besprechen und um gleichzeitig den nächsten Sprint zu planen.', //company_fourth_section_right_cards_descriptions[3] should change with basedOnAcceptance

      //companyFifthSectionTitle[0]: we have one OUR in this Section
      team: 'TEAM',
      softwareEngineers: 'SOFTWARE-INGENIEURE', //company_fifth_section_people[0] should change with softwareEngineers
      qualityAssurance: 'QUALITÄTSSICHERUNG', //company_fifth_section_people[1] should change with qualityAssurance
      financeManagement: 'FINANZEN & MANAGEMENT', //company_fifth_section_people[2] should change with financeManagement
      projectManagers: 'PROJEKTMANAGER', //company_fifth_section_people[3] should change with projectManagers
      uiUxDesigners: 'UI/UX DESIGNER', //company_fifth_section_people[4] should change with uiUxDesigners

      techStack: 'TECH STACK', //company_final_section_title1 should change with techStack

      infrastructure: 'INFRASTRUKTUR', //company_final_section_title[0] should change with infrastructure
      asCode: 'AS CODE', //company_final_section_title2[1] should change with asCode

      capitalProjects: 'PROJECTS',
      //works_title should change with projects

      worksDescription:
        'Rapid changes in consumer trends have caused the need for innovation and digital transformation for many industries worldwide.',
      //works_description1[0] should change with workDescription

      worksDescription1:
        'View some of the most successful projects and more by our specialists presented in this gallery. We work to make your business start effectively working for you.',
      //works_description1[1] should change with workDescription1

      worksDescription2:
        'Immer schnellere Veränderungen bei Verbrauchertrends erfordern einen hohen Grad an Innovation und digitale Transformation weltweit',

      worksDescription2Mobile:
        'Immer schnellere Veränderungen bei Verbrauchertrends erfordern einen hohen Grad an Innovation und digitale Transformation weltweit',
      //works_description2Mobile

      insideWorkNext: 'Next Project',
      insideWorkPrev: 'Prev Project',

      date: 'Datum', //inside_work_details[0] should change with date
      client: 'Kunde', //inside_work_details[1] should change with client
      category: 'Kategorie', //inside_work_details[2] should change with category

      latestBlogs: 'LATEST BLOGS & NEWS',
      blogDescription: 'Get informed with lates Blog & News.',
      blogReadMore: 'read more',
      blogTags: 'Tags',
      realtedBlogs: 'Related Blogs',
      capitalCareer: 'KARRIERE',
      freshBlood: 'WIR SUCHEN STETIG NACH TALENTEN', //carrer_banner_title_big[0] should change with freshBlood
      always: 'DIE NOCH BESSER SIND', //carrer_banner_title_big[1] should change with always,
      welcomed: 'ALS WIR', //carrer_banner_title_big[2] should change with welcomed

      carrerBannerTitleDescription:
        'Unser Team wächst weiter und deshalb brauchen wir DICH, um bei unseren Brainstormings uns mit lösungsorientierten Ideen zu unterstützen.',

      open: 'Offene', //carrer_first_section_title[0] should change with open
      positions: 'Stellen', //carrer_first_section_title[1] should change with Positions

      perks: 'Unsere &', //carrer_final_section_title[0] should change with perks
      benefits: 'Vorteile', //carrer_final_section_title[1] should change with benefits

      apply: 'Anwenden',
      applyNow: 'Jetzt Bewerben',
      firstName: 'Vorname',
      lastName: 'Nachname',
      email: 'Email',
      phoneNumber: 'Telefonnummer',
      yourCv: 'Dein Cv',
      submitApplication: 'Bewerbung Einreichen',
      chooseFile: 'Datei Wählen',

      letsStart: 'Lasst uns beginnen', //contact_title_big[0] should change with letsStart
      working: 'arbeiten', //contact_title_big[1] should change with letsStart
      together: 'zusammen', //contact_title_big[2] should change with letsStart

      needsDiscuss: 'Lassen sie uns Ihre bedürfnisse besprechen!',

      fullNameMustBe: 'Full Name must be longer than 2 letters!', //contact_errors[0] should change with fullNameMustBe
      subjectMustBe: 'Subject must be longer than 5 letters!', //contact_errors[1] should change with subjectMustBe
      enterValidEmail: 'Please enter a valid email!', //contact_errors[2] should change with enterValidEmail
      messageMustbe: 'Message must be longer than 10 letters!', //contact_errors[3] should change with messageMustbe
      fieldsAreNotValid: 'Fields are not valid !', //contact_errors[4] should change with fieldsAreNotValid

      sendMessage: 'Nachricht senden',
      generalInquiries: 'Allgemeine Anfragen',
      location: 'Location',

      contactLocation0: 'TECH FRAME LLC Nearshore center',

      contactLocation1: 'Agim Ramadani,',
      //contact_location_data[0] should change with contactLocation1
      contactLocation2: 'Bregu i Diellit 10000,', //contact_location_data[1] should change with contactLocation1
      contactLocation3: 'Pristina, Kosovo.', //contact_location_data[2] should change with contactLocation1

      getLocation: 'Get Location',
      follow: 'Follow',
      home: 'HEIMAT', //header_titles should[0] change with home
      company: 'UNSER UNTERNEHMEN', //header_titles should[1] change with company
      works: 'UNSERE PROJEKTE', //header_titles should[2] change with works
      blog: 'BLOG', //header_titles should[3] change with blog
      contact: 'KONTAKT', //header_titles should[4] change with contact

      footerTitle: 'Wir freuen uns auf eine Zusammenarbeit!',

      digitalization: 'Digitalisierung', //cards_6[0] should change with digitalization
      uiUxDesign: 'UI/UX Design & Branding Services', //cards_6[1] should change with uiUxDesign
      webAppDev: 'WEB-Anwendungen', //cards_6[2] should change with webAppDev
      mobileDev: 'App-Entwicklungen', //cards_6[3] should change with mobileDev
      qaManual: 'Digitalisierte Qualitätssicherung', //cards_6[4] should change with qaManual
      techSupport: 'Technischer Support', //cards_6[5] should change with techSupport

      cardsDescription1:
        'Stetige Veränderungen in unserer modernen Welt erzwingen die Automatisierung vieler Kommunikations- Kooperationsprozesse. Online-Bestellungen und -Bezahlungen, Produkt-Verteilung und -Versand bis hin zur Produktion von Gütern erfordern eine konsequente Digitalisierung, um mit der Geschwindigkeit von Veränderungenmithalten zu können.', //cards_description_6[0] should change with cardsDescription1
      cardsDescription2:
        'Digitalisierung bedeutet nicht nur Transformation objektiver Prozesse sondern zunehmend auch subjektive Geschäftsprozesse, welche eng mit Empfindungen wie Sehen, Fühlen, Glauben, Vertrauen verknüpft sind. Marken, die in einem stark objektiven Umfeld entstanden und erfolgreich geworden sind, wandeln sich heute zunehmend in eine online-gestützte Markenwahrnehmung mit transparenten Kundenbewertungen und empfehlungen.', //cards_description_6[1] should change with cardsDescription2
      cardsDescription3:
        'Maßgeschneiderte Lösungen zur Vernetzung komplexer Einzelvorgänge in Ihrem Unternehmen, wie z.B. Team Work, Projektmanagement, Beschaffung.', //cards_description_6[2] should change with cardsDescription3
      cardsDescription4:
        'Bis zu 90% unserer Zeit verbringen wir mit Arbeiten auf dem Smartphone. Gerade deshalb sind mobile App-Anwendungen die beliebteste Form der Digitalisierung von Geschäftsprozessen.', //cards_description_6[3] should change with cardsDescription4
      cardsDescription5:
        'Der Ausgleich von Abweichungen, Korrekturen und vorbeugende Maßnahmenzur Qualitätsabsicherung interner Geschäftsprozesse und Produktionsabläufe bieten ein weites Feld für digitalisierte Prozessschritte.', //cards_description_6[4] should change with cardsDescription5
      cardsDescription6:
        "Our company's Tech Support Officers are willing to assist and resolve your company's technical issues, provide timely customer feedback, support the roll-out of new applications, talk to customers directly, as well as create written documentation, with excellent written and verbal communication.", //cards_description_6[5] should change with cardsDescription6

      paidHolidays: 'Bezahlte Feier- und Urlaubstage', //benefits_12[0] should be change with
      paidSickDays: 'Bezahlte Krankheitstage', //benefits_12[1] should be change with
      casualDress: 'Dress code: lässig und leger', //benefits_12[2] should be change with
      freeCoffee: 'Kostenloser Kaffee', //benefits_12[3] should be change with
      comeMealsProvided: 'Für Verpflegung wird gesorgt', //benefits_12[4] should be change with
      companyOutings: 'Betriebsausflüge', //benefits_12[5] should be change with
      stockedKitchen: 'Eine gut befüllte Küche', //benefits_12[6] should be change with
      jobTraining: 'Weiterbildungsprogramme & Konferenzen', //benefits_12[7] should be change with
      lunchLearns: 'Mittagessen & Lernen', //benefits_12[8] should be change with
      gameRoom: 'Chill-Area mit unterschiedlichen Spielen', //benefits_12[9] should be change with
      recreationalClubs: 'Freizeitclubs', //benefits_12[10] should be change with
      diversityProgram: 'Programme zur Förderung der Diversität', //benefits_12[11] should be change with
      homeFirstSectionDiscription1:
        'Viele unserer Kunden hatten im Laufe der Jahre unterschiedliche Anforderungen an die Digitalisierung von Geschäftsaktivitäten, Prozessen und die Steigerung der Effizienz. Wir bauen mit den innovativsten Technologien, um die intelligentesten Lösungen zu liefern, die im täglichen Betrieb bessere Ergebnisse liefern, und wir verbessern uns ständig.',

      homeFirstSectionDiscription2:
        'Unsere Anforderungsingenieure und unsere Planungsabteilung sind hochqualifiziert in der Analyse Ihrer betrieblichen Prozesse, und durch die enge Zusammenarbeit mit Ihrer Organisation werden viele dieser Prozesse digital – wodurch diese Prozesse nahtloser und effizienter werden.,',

      message: 'Nachricht', //placeholders_4[3] should change with message
      subject: 'Thema', //placeholders_4[4] should change with subject
      fullName: 'Vollständiger Name', //placeholders_4[5] should change with fullname

      eLearaningDsc1:
        'Es gibt eine starke Skalierbarkeit von digitalen Transformationen. Sie findet statt beiVernetzung, auf Plattformen, in Algorithmen und Datentanks. Eine starke Verknüpfung von Technologien und Märkten ist ein Schlüsselfaktor für erfolgreiche digitale Transformation.EdTech ist der Schlüssel für zukünftige Lernprozesse im digitalen Zeitalter. EdTech eröffnet neue Interaktionsmöglichkeiten, zwischen Lehrer und Lehrer, Lehrer und Schüler/Student, Schüler und Schüler. Formen von Unterricht, Prüfung und Bewertung werden on-line gestaltet. Neue Plattformen für interaktives E-Learning ermöglichen u.a. mobiles Lernen, Lernen im Team und sehr flexible Prüfungs/Bewertungsmethoden.',

      telecomunicationDsc2:
        'Stimme, Bild, Video, Daten...alles ist Bestandteil der modernen täglichen Telekommunikation. Wir fokussieren uns auf die Bereitstellung integraler Lösungen für Unternehmen, wo alle Bestandteile aus einer Anwendersoftware nutzerfreundlich bereitgestellt werden können.',

      agricultureDsc3:
        'Digitale Technologien unterstützen den Prozess-Flow in der modernen Landwirtschaft durch ständige Messdaten-Analyse, künstliche Intelligenz und mobile App-Anwendungen zur optimalen Steuerung und Überwachung von Pflanzen- und Tierzuchtprozessen. Farm-Digitalisierung erlaubt unseren Kunden das Fine-Tuning von Dosierung und Timing der Versorgungszyklen und verringert die Notwendigkeit teurer labortechnischer Analysen vor Ort. Über vernetzte, hochempfindliche Sensoren kann der gesamte Prozess zur Nährstoffanreicherung von Erde oder Wassser fernüberwacht und gesteuert werden.',

      eCommerceDsc4:
        'E-Commerce hat unsere Welt für Handel und Vertrieb komplett verändert. Über den Einsatz eigener E-Commerce-Lösungen haben nun auch kleinere Unternehmen Zugang zu einer größeren Anzahl von Marktteilnehmern und regionen.',

      humanResourcesDsc5:
        'Digitalisierung im Personalmanagement bedeutet die Ablösung herkömmlicher Personal-Aktenverwaltung durch papierlose Prozesse der Personalaktenverwaltung und -archivierung, bis hin zur Verwaltung von Personalentwicklungsprogrammen fürMitarbeiterpotentiale auf jeder Hierarchieebene des Unternehmens.Techframe digitalisiert Personalmanagementprozesse auch für den erleichterten Zugang durchs Management zu relevanten Informationen bezüglich Personalentscheidungen.',

      businessProcessAutomationDsc6:
        'Die Automatisierung interner Geschäftsprozesse bedeutet konsequente Nutzung digitalisierbarer Vorgänge zur Einsparung von menschlichen Eingriffen in die Prozesse. Diese führt zur Einsparung von Personalkosten, Verringerung von Fehlerquoten und erhöhter Transparenz bezüglich interner Geschäftsprozesse. Damit sichern sich unsere Kunden den entscheidenden Wettbewerbsvorteil in ihremGeschäft.',
    },
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: resourcesi18n,
    fallbackLng: 'en',
    lng: _language, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
export default i18n
