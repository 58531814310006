import React, { Component } from 'react'
import lines from '../../assets/img/lines.svg'
import apiService from '../../services/apiService'
import { company_final_section_title2 } from '../../locales/translation'
import infra from '../../assets/img/chess/infrastucture.jpg'
import { withTranslation } from 'react-i18next'

class PCRD extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tools: [],
    }
  }

  componentDidMount() {
    apiService.getTools().then(({ data: response }) => {
      this.setState({
        tools: response,
      })
    })
  }

  render() {
    const { t } = this.props
    const { tools } = this.state
    return (
      <React.Fragment>
        <div
          style={{
            marginTop: 0,
          }}
          className={`ldf agileDiv`}
        >
          <div className="container">
            <div className={``}>
              <div
                style={{
                  background: `url(${infra})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  backgroundPosition: '100% 80%',
                }}
                className={`right-part mTop50`}
              ></div>
              <div className={`left-part mTop50`}>
                <div
                  style={{
                    marginBottom: 40,
                  }}
                  className="logosTopImgText flipText whoWeAreDivTitle wrapMobile newTechWidthText"
                >
                  <img alt="linesMobile" className="linesMobile" src={lines} />
                  <div>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        textTransform: 'uppercase',
                        color: '#000347',
                      }}
                    >
                      {t('infrastructure')}{' '}
                    </h2>
                    {/* <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        textTransform: "uppercase",
                        color: "#000347",
                      }}
                    >
                      {t('asCode')}
                    </h2> */}
                  </div>
                </div>
                <div className={`grid flipProjects`}>
                  {tools.map((element, index) => (
                    <div
                      key={index}
                      className={` grid-4 grid-3 logos flipLogos logosMobile`}
                    >
                      <img src={element.image} alt="" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(PCRD)
