import React, { useEffect, useState } from 'react'
import Benefits from './benefits'
import OpenPositions from './openPositions'
import Loader from '../loader/loader'
import aboutUS from '../../assets/img/techFramePics/career.jpg'
import { getLanguageState } from '../../store/reducers/languageReducer'
import { connect, useSelector } from 'react-redux'
import RenderSmoothImage from 'render-smooth-image-react'
import { withTranslation } from 'react-i18next'
import { t } from 'i18next'
import { withRouter } from 'react-router'
// import {
//   carrer_banner_title_small,
//   carrer_banner_title_big,
//   carrer_banner_title_description,
// } from '../../locales/translation'

export const Career = ({ languageVal }) => {
  const [loading, setLoading] = useState(true)
  const [language, setLanguage] = useState('en')
  const languageState = useSelector(getLanguageState)

  const setLoadingHandler = () => {
    setLoading(false)
  }

  useEffect(() => {
    const scrollPos = sessionStorage.getItem('scrollPos')
    if (scrollPos) {
      window.scroll({
        top: scrollPos,
        behavior: 'smooth',
      })
    }
  }, [])

  useEffect(() => {
    document.body.classList.add('white-menu')
    setLanguage(languageState.language)
  }, [languageState.language])

  return (
    <React.Fragment>
      <Loader loading={loading} />
      <div className={`lines-bg`}></div>
      <div className={`career`}>
        <div style={{ marginTop: 86 }} className={`first-section`}>
          <div className={`first-container`}>
            <div className={`second-container extra-container`}>
              <div className={`container`}>
                {/* <div className="grid pleft15">
                  <div className="grid-2 grid-1">
                    <div className="career-banner-section-text">
                      <h4>{t('career')}</h4>
                      <h1>
                        {t('freshBlood')}
                        <br /> {t('always')}{" "}
                        <span
                          style={{ fontFamily: "black-font" }}
                          className="colored carrerTextH"
                        >
                          {t('welcomed')}
                        </span>
                      </h1>
                      <p
                        style={{
                          color: "#919191",
                          fontFamily: "ligh-font",
                          fontSize: "16px",
                        }}
                      >
                        {t('carrerBannerTitleDescription')}
                      </p>
                    </div>
                  </div>
                  <div className="grid-2 grid-1">
                    <div
                      style={{
                        background: `url(${aboutUS})`,
                      }}
                      className="career-banner-section-img"
                    ></div>
                  </div>
                </div> */}
                <div className="carrerBanner">
                  <div className="carrerBannerTextNew">
                    <h4 className={languageVal === 'de' ? 'deHeader' : ''}>
                      {t('career')}
                    </h4>
                    <div className="h1AbsoluteDIv">
                      <h1>
                        {t('freshBlood')} {t('always')}{' '}
                        <span
                          style={{ fontFamily: 'black-font', color: '#00A9FC' }}
                          className="colored carrerTextH"
                        >
                          {t('welcomed')}
                        </span>
                      </h1>
                    </div>
                    <p
                      style={{
                        color: '#2D2D40',
                        fontFamily: 'ligh-font',
                        fontSize: '18px',
                        marginTop: 10,
                      }}
                    >
                      {t('carrerBannerTitleDescription')}
                    </p>
                  </div>
                  <div className="carrerBannerImage">
                    {/* <img src={aboutUS} alt="" /> */}
                    <RenderSmoothImage src={aboutUS} alt="alternate-text" />
                    <div className="carrerBannerImageLayer"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`first-container`}>
          <div className={`second-container extra-container`}>
            <div className={`container`}>
              <OpenPositions loading={setLoadingHandler} language={language} />
              <Benefits />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = (state) => {
  return { languageVal: state.language.language }
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, null)(Career)),
)
