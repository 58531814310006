import React, { Component } from 'react'
import downArrow from '../../assets/img/down-arrow.svg'
import { company_banner_button_text } from '../../locales/translation/'
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa'
import { withTranslation } from 'react-i18next'

class FirstSection extends Component {
  scroll = () => {
    document.getElementById('footer').scrollIntoView({ behavior: 'smooth' })
  }
  scrollDown = () => {
    document
      .getElementById('secondSectionHome')
      .scrollIntoView({ behavior: 'smooth' })
  }

  render() {
    const { t } = this.props
    console.log({ t })
    return (
      <React.Fragment>
        <div className={`home-page`}>
          <div className={`home-page_text pleft15 break-point`}>
            <span
              className={`wow fadeInUp desktop-text`}
              data-wow-duration="0.5s"
              data-wow-delay="0.8s"
            >
              {t('weDo')}{' '}
              <span className="emphasise">
                {t('digital')} {t('transformation')}
              </span>{' '}
              <br />
              {t('throughMultiscreen')}{' '}
              <span className="emphasise">{t('smartSolution')}</span>!
            </span>
            {/* <span
              className={`wow fadeInUp mobile-text`}
              data-wow-duration="0.5s"
              data-wow-delay="0.8s"
            >
              {home_banner_title[0]}
              <br />{' '}
              <span className="emphasise">
                {home_banner_title[1]} <br /> {home_banner_title[2]}
              </span>{' '}
              <br />
              {home_banner_title[3]} <br />{' '}
              <span className="emphasise">{home_banner_title[4]}</span>!
            </span> */}
            <div></div>
            <a
              id="companyPresentationBtn"
              style={{
                textDecoration: 'none',
                fontSize: 18,
                margin: 0,
                padding: 10,
                paddingRight: 20,
                paddingLeft: 20,
                outline: 'none',
                border: 'none',
                width: 'fit-content',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                marginTop: 30,
                color: 'white',
                backgroundColor: '#05A8FE',
              }}
              href="/tframe2.pdf"
              target="_blank"
            >
              <p>{t('companyBannerButtonText')}</p>
            </a>
          </div>
          <div className={`social-home pleft15`}>
            <a
              href="https://www.facebook.com/tframelabs"
              target="_blank"
              className={`wow fadeInUp`}
              data-wow-duration="0.5s"
              data-wow-delay="0.8s"
              rel="noopener noreferrer"
            >
              <FaFacebookF className="footerSocialIcon" />
            </a>
            <a
              href="https://www.instagram.com/techframelabs"
              target="_blank"
              className={`wow fadeInUp`}
              data-wow-duration="0.5s"
              data-wow-delay="0.9s"
              rel="noopener noreferrer"
            >
              <FaInstagram className="footerSocialIcon" />
            </a>
            <a
              href="https://www.linkedin.com/company/techframelabs"
              target="_blank"
              className={`wow fadeInUp`}
              data-wow-duration="0.5s"
              data-wow-delay="1s"
              rel="noopener noreferrer"
            >
              <FaLinkedinIn className="footerSocialIcon" />
            </a>
          </div>
          <div className="scroll-arrow">
            <img onClick={this.scrollDown} src={downArrow} alt="" />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(FirstSection)
