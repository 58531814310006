import {combineReducers,compose,applyMiddleware,createStore} from 'redux'
import thunk from 'redux-thunk'
import languageReducer from './reducers/languageReducer'

const composeEnhancers = (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

const rootReducer = combineReducers({
    language:languageReducer
})

export const store = createStore(rootReducer,composeEnhancers(applyMiddleware(thunk)));