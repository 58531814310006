import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import apiService from '../../services/apiService'
import quotes from '../../assets/img/quotes.svg'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { home_final_section_testimonial_left } from '../../locales/translation'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

class ClientBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      testimonials: [],
      partners: [],
    }
  }

  testimonials = async () => {
    if (this.props.language) {
      await apiService
        .getTestimonials(this.props.language)
        .then(({ data: response }) => {
          this.setState({
            testimonials: response,
          })
        })
    }
  }

  partners = async () => {
    if (this.props.language) {
      await apiService
        .getPartners(this.props.language)
        .then(({ data: response }) => {
          this.setState({
            partners: response,
          })
        })
    }
  }

  componentDidMount() {
    this.testimonials()
    this.partners()
  }

  render() {
    const { testimonials, partners } = this.state
    console.log(testimonials)
    console.log(this.props.language)
    const { t } = this.props

    return (
      <div className={`client_box`}>
        <div className={`left-part`}>
          <div className={`left-part_`}>
            <span
              className={`number wow fadeInUp`}
              data-wow-duration="0.5s"
              data-wow-delay="0.5s"
            >
              23
            </span>
            <span
              className={`text wow fadeInUp`}
              data-wow-duration="0.5s"
              data-wow-delay="0.7s"
            >
              {t('clients')}
            </span>
          </div>
          <div className={`left-part_`}>
            <span
              className={`number wow fadeInUp`}
              data-wow-duration="0.5s"
              data-wow-delay="0.5s"
            >
              27
            </span>
            <span
              className={`text wow fadeInUp`}
              data-wow-duration="0.5s"
              data-wow-delay="0.7s"
            >
              {t('projects')}
            </span>
          </div>
          <div className={`left-part_`}>
            <span
              className={`number wow fadeInUp`}
              data-wow-duration="0.5s"
              data-wow-delay="0.5s"
            >
              5
            </span>
            <span
              className={`text wow fadeInUp`}
              data-wow-duration="0.5s"
              data-wow-delay="0.7s"
            >
              {t('countries')}
            </span>
          </div>
        </div>
        {testimonials[0] && (
          <div className={`pleft15 right-part`}>
            <img className="quotes-right" src={quotes} alt="" />
            <OwlCarousel
              className="owl-theme"
              loop
              margin={1}
              items={1}
              autoplay={false}
              nav={true}
              dots
            >
              {testimonials.map((element, index) => (
                <div key={index} className="item">
                  <div className="testimonial-item">
                    <img src={element.image} alt="" />
                    <p
                      style={{
                        color: '#2D2D40',
                        fontWeight: 'lighter',
                        fontFamily: 'ligh-font',
                        lineHeight: '24px',
                        fontSize: '16px',
                      }}
                    >
                      ‘’
                      {element.testimonial}
                      ’’
                    </p>
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          marginTop: 20,
                          alignItems: 'center',
                        }}
                      >
                        <p
                          style={{ margin: 0, color: '#00a9ff' }}
                          className="full-name"
                        >
                          {element.full_name}
                        </p>
                        <a
                          href={`${
                            element.linkedin_profile
                              ? element.linkedin_profile
                              : '/'
                          }`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: 'none',
                            marginLeft: 5,
                            borderLeft: '1px solid grey',
                            paddingLeft: 5,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <LinkedInIcon
                            style={{ fontSize: 30, color: '#00a9ff' }}
                          />
                        </a>
                      </div>
                      <p style={{ marginTop: 0 }} className="full-name">
                        {element.position && element.position}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </OwlCarousel>
            <img className="quotes-left" src={quotes} alt="" />
          </div>
        )}
        {partners[0] && (
          <div className={`grid partners`}>
            <div
              className="second-section_title pleft15 wow fadeInUp animated"
              data-wow-duration="0.5s"
              data-wow-delay="0.5s"
            >
              <img src="/static/media/lines.725182aa.svg" alt="" />
              <span className={'some-partners'}>Some of Our Partners</span>
            </div>
            {partners.map((element, index) => {
              return (
                <div
                  key={index}
                  className={`grid-6 grid-3 grid-2 partner-box  wow fadeInUp`}
                  data-wow-duration="0.5s"
                  data-wow-delay="0.5s"
                >
                  <img src={element.image} alt="" />
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return { language: state.language.language }
}
export default withRouter(
  withTranslation()(connect(mapStateToProps, null)(ClientBox)),
)
