import React, { useEffect, useState } from 'react'
import { inside_blog_tags } from '../../locales/translation'
import renderHTML from 'react-render-html'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import BeforeAfterSlider from 'react-before-after-slider'
import { useSelector } from 'react-redux'
import { getLanguageState } from '../../store/reducers/languageReducer'
import { withTranslation } from 'react-i18next'

const BlogContent = ({ blog, blogs }) => {
  const [imageWidth, setImageWidth] = useState(0)
  const languageState = useSelector(getLanguageState)

  const onScreenChange = () => {
    const containerWidth = document.getElementById('beforeAfterContainer')
      ?.offsetWidth
    setImageWidth(containerWidth)
  }

  useEffect(() => {
    const containerWidth = document.getElementById('beforeAfterContainer')
      ?.offsetWidth
    setImageWidth(containerWidth)
  }, [])

  useEffect(() => {
    window.addEventListener('resize', onScreenChange)
    const abortController = new AbortController()
    return () => {
      window.removeEventListener('resize', onScreenChange)
      abortController.abort()
    }
  }, [])

  return (
    <>
      <div style={{ maxWidth: 1100 }} className={`container extra-container`}>
        <div
          id="beforeAfterContainer"
          className={`text-editor ql-editor inside-blog`}
        >
          {renderHTML(
            `${
              languageState.language === 'en' ? blog.content : blog.de_content
            }`,
          )}
        </div>
        {blog?.after_image && blog?.before_image && (
          <BeforeAfterSlider
            before={blog?.before_image}
            after={blog?.after_image}
            width={imageWidth}
            className="beforeAfterImg"
          />
        )}
        <div
          style={{
            borderTop: '1px solid #cccaca',
            paddingTop: 10,
            alignItems: 'center',
          }}
          className="other-details"
        >
          {blog.categories?.length > 0 && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
              }}
              className="tags"
            >
              {inside_blog_tags}:{' '}
              {blog.categories?.map((item, index) => (
                <div key={index + '-' + item} className="tagProject margB">
                  <span> {item?.name}</span>
                </div>
              ))}
            </div>
          )}
          <div className="social-icon-wrapper">
            <a
              className="reactIconHoverDiv"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftframe.de%2Fwork%2F${blog.title}&amp;src=sdkpreparse`}
            >
              <FaFacebookF className="reactIconHover" />
            </a>
            <a
              className="reactIconHoverDiv"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://www.linkedin.com/sharing/share-offsite/?url=${window.location.href}`}
              style={{ borderLeft: 'none' }}
              to="#"
            >
              <FaLinkedinIn className="reactIconHover" />
            </a>
          </div>
        </div>
      </div>
    </>
  )
}

export default withTranslation()(BlogContent)
