  import React, { useState } from "react";
  import "./index.css";
  import DeFlag from "../../../assets/img/gerFlag.svg";
  import UkFlag from "../../../assets/img/engFlag.svg";
  import { useTranslation } from "react-i18next";
  import { connect } from "react-redux";
  import { changeLanguage } from "../../../store/actions/language";
  import { withRouter } from "react-router";
  import { useEffect } from "react";

  const Switch = (props) => {
    const [isDeFront, setIsDeFront] = useState(true);
    const initialValue = localStorage.getItem("languageVal") || "de";
    const { changeLanguage } = props;
    const { i18n } = useTranslation();
    const [value, setValue] = useState(initialValue);

    useEffect(() => {
      changeLanguage(value);
      setIsDeFront(value === "en");
    }, [value, changeLanguage]);

    const toggleClick = () => {
      setTimeout(() => {
        if (value === "en") {
          i18n.changeLanguage("de");
          setValue("de");
          localStorage.setItem("languageVal", "de");
        } else {
          setValue("en");
          i18n.changeLanguage("en");
          localStorage.setItem("languageVal", "en");
        }
        setIsDeFront(!isDeFront);
      }, 200);
    };

    

    return (
      <div className="form">
        {/* <img
          onClick={toggleClick}
          className={`swiping-img ${
            value === "de" ? "swipe-left" : "swipe-right"
          }`}
          src={value === "de" ? DeFlag : UkFlag}
          alt=""
        />
        <div className="form__container">
          <img src={logotranslate} alt="" />
          
        </div> */}
        <div onClick={toggleClick} className="form-inputs">
          <div className="form-inputs-images">
            <img
            className={`swiping-img-de ${isDeFront ? 'front' : 'back'}`}
            src={UkFlag}
            alt=""
          />
          <img
            className={`swiping-img-en ${isDeFront ? 'back' : 'front'}`}
            src={DeFlag}
            alt=""
          />
          </div>
            <div className="form-text">{value === "en" ? "EN" : "DE"}</div>
        </div>
        
      </div>
    );
  };

  const mapDispatchToProps = {
    changeLanguage,
  };

  export default connect(null, mapDispatchToProps)(withRouter(Switch));
