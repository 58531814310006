import React, {useEffect, useState} from 'react'
import Home from './components/home/index'
import AboutUs from './components/about-us/about-us'
import Projects from './components/projects/projects'
import NavMenu from './components/nav-menu/nav-menu'
import {Switch, Route} from 'react-router-dom'
import {withRouter} from 'react-router'
import Footer from './components/footer/footer'
import InsideProject from './components/projects/insideProjects'
import Career from './components/career/career'
import InsideCareer from './components/career/insideCareer'
import Blog from './components/blog/blog'
import InsideBlog from './components/blog/insideBlog'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ScrollToTop from './hoc/scrollToTop'
import Contact from './components/contactPage/contact'
import {useDispatch} from 'react-redux'
import {getLanguage} from './store/actions/language'
import useGeoLocation from 'react-ipgeolocation'
import 'render-smooth-image-react/build/style.css'

const App = () => {
    const dispatch = useDispatch()
    const location = useGeoLocation()
    const [language, setLanguage] = useState('de')

    window.addEventListener('scroll', () => {
        sessionStorage.setItem('scrollPos', window.scrollY)
    })

    useEffect(() => {
        if (location.country) {
            if (location.country === 'DE') {
                localStorage.setItem('language', 'de')
                dispatch(getLanguage())
                setLanguage('de')
            } else {
                localStorage.setItem('language', 'en')
                dispatch(getLanguage())
                setLanguage('en')
            }
        }
    }, [dispatch, location.country])

    return (
        <React.Fragment>
            <ScrollToTop>
                <Switch>
                    <React.Fragment>
                        <NavMenu/>
                        <Route path="/contact" exact component={Contact}/>
                        <Route path="/" exact component={Home}/>
                        <Route path="/aboutus" exact component={AboutUs}/>
                        <Route path="/works" exact component={Projects}/>
                        <Route path="/blogs" exact component={Blog}/>
                        <Route path="/work/:title" exact component={InsideProject}/>
                        <Route path="/career" exact component={Career}/>
                        <Route path="/job/:title" exact component={InsideCareer}/>
                        <Route path="/blog/:title" exact component={InsideBlog}/>
                        <Footer/>
                    </React.Fragment>
                </Switch>
            </ScrollToTop>
            <ToastContainer/>
        </React.Fragment>
    )
}

export default withRouter(App)
