import React, { Component } from 'react'
import lines from '../../assets/img/lines.svg'
import { withRouter } from 'react-router-dom'
import timeframe from '../../assets/img/timeframe.mp4'
import image from '../../assets/img/techFramePics/Homepage1.jpg'
import Projects from './projects'
import Clients from './clients'
// import {
//   home_first_section_title,
//   home_first_section_description1,
//   home_first_section_description2,
//   home_third_section_title,
//   home_third_section_button_text,
//   home_first_section_title1,
// } from '../../locales/translation'
import Cards5 from '../about-us/aboutUsSection2/cards5'
import Collapse from '@material-ui/core/Collapse'
import { withTranslation } from 'react-i18next'

class SecondSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      readMore: false,
    }
  }

  // componentDidMount() {
  //   if (this.props.currentPage === "Home") {
  //     document.getElementById("video").setAttribute("autoplay", true);
  //   }
  // }

  scroll = () => {
    document.getElementById('footer').scrollIntoView()
  }

  render() {
    const { t } = this.props
    return (
      <React.Fragment>
        <div className={`extra-container `}>
          <div id="secondSectionHome" className="underbannerHomeConatiner">
            <div className="blueSquareLeft"></div>
            <div className="blueSquareRight"></div>
            <div className="underBannerLayer"></div>
            <img className="underbannerHomeImage" src={image} alt="img" />
            <div className="underHomeBannerDivText">
              <img src={lines} alt="" />
              <span style={{ marginLeft: 20 }}>
                {t('homeFirstSectionTitle')}
                <span style={{ color: '#00BDFC' }}>
                  {t('homeFirstSectionTitle1')}
                </span>
              </span>
            </div>
            <div className="underHomeBannerDiv">
              {/* <div className="underHomeBannerDivText">
                  <img src={lines} alt="" />
                  <span style={{ marginLeft: 20 }}>
                    {home_first_section_title}
                    <span style={{ color: "#00BDFC" }}>
                      {home_first_section_title1}
                    </span>
                  </span>
                </div> */}
              <div
                className={`underHomeBannerDivTextP`}
                data-wow-duration="0.5s"
                data-wow-delay="0.8s"
              >
                <p className="underHomeBannerP">
                  {t('homeFirstSectionDiscription1')}
                </p>
                <p className="underHomeBannerP">
                  {t('homeFirstSectionDiscription2')}
                </p>
              </div>
            </div>
            <div className="container">
              <div className="underHomeBannerDivMobile">
                <div className="underHomeBannerDivTextMobile">
                  <img src={lines} alt="" />
                  <span
                    style={{
                      marginLeft: 20,
                      fontFamily: 'black-font',
                      color: '#000347',
                    }}
                  >
                    {t('homeFirstSectionTitle')}
                    <span
                      style={{ fontFamily: 'black-font', color: '#000347' }}
                    >
                      {t('homeFirstSectionTitle1')}
                    </span>
                  </span>
                </div>
                <div
                  className={`underHomeBannerDivTextPMobile`}
                  data-wow-duration="0.5s"
                  data-wow-delay="0.8s"
                >
                  <p className="underHomeBannerP">
                    {t('homeFirstSectionDiscription1')}
                  </p>
                  <Collapse in={this.state.readMore}>
                    <p className="underHomeBannerP">
                      {t('homeFirstSectionDiscription2')}
                    </p>
                  </Collapse>
                  <u
                    onClick={() => this.setState({ readMore: true })}
                    style={{
                      display: this.state.readMore && 'none',
                      marginTop: 0,
                      fontSize: 14,
                    }}
                    className="underHomeBannerP"
                  >
                    read more
                  </u>
                  <u
                    onClick={() => this.setState({ readMore: false })}
                    style={{
                      display: !this.state.readMore && 'none',
                      marginTop: 0,
                      fontSize: 14,
                    }}
                    className="underHomeBannerP"
                  >
                    read less
                  </u>
                </div>
              </div>
            </div>
          </div>
          <div className={`second-section`}>
            <Projects />
          </div>
          {/* // */}

          <div className="aboutusContainerSecondBox1">
            <div className="whoAreWeDivBoxes">
              <div
                className="rightSquaresHome"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: 30,
                }}
              >
                <div className="whoWeAreDivTitle padleftCom">
                  <img alt="linesMobile" className="linesMobile" src={lines} />
                  <div>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        color: '#000347',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('homeThirdSectioTitle')}{' '}
                    </h2>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        color: '#000347',
                        textTransform: 'uppercase',
                      }}
                    >
                      {t('homeThirdSectioTitle1')}
                    </h2>
                  </div>
                </div>
                <button
                  style={{ fontSize: 18, marginRight: 17 }}
                  onClick={() => this.props.history.push('/aboutus')}
                  className="fullContactButton aa11 hoverUp up"
                >
                  {t('homeThirdSectionButtonText')}
                </button>
              </div>

              <Cards5 />
            </div>
          </div>
          {/* <div className={`second-section services`}>
            <div className="container">
              <div
                className={`second-section_title pleft15 wow fadeInUp noPleft`}
                data-wow-duration="0.5s"
                data-wow-delay="0.5s"
              >
                <div>
                  <img src={lines} alt="" />
                  <span>
                    {home_third_section_title[0]}
                    <br />
                    {home_third_section_title[1]}
                  </span>
                </div>
                <div className="btn-wrapper">
                  <Link className='hoverUp up' to="/aboutus">{home_third_section_button_text}</Link>
                </div>
              </div>
              <div className={`second-section_boxes`}>
                <div className={`grid`}>
                  <div
                    className={`grid-5 grid-4 grid-3 grid-2 boxes wow fadeInUp`}
                    data-wow-duration="0.5s"
                    data-wow-delay="0.5s"
                  >
                    <div className={`inside_boxes top`}>
                      <img src={Digitalization} alt="" />
                      <span>{t('digitalization')}</span>
                    </div>
                  </div>
                  <div
                    className={`grid-5 grid-4 grid-3 grid-2 boxes wow fadeInUp`}
                    data-wow-duration="0.5s"
                    data-wow-delay="0.7s"
                  >
                    <div className={`inside_boxes top`}>
                      <img src={UIUXDesign} alt="" />
                      <span>
                        {t('uiUxDesign').split("&")[0]} &<br />{" "}
                        {t('uiUxDesign').split("&")[1]}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`grid-5 grid-4 grid-3 grid-2 boxes wow fadeInUp`}
                    data-wow-duration="0.5s"
                    data-wow-delay="0.9s"
                  >
                    <div className={`inside_boxes top`}>
                      <img src={WebApplication} alt="" />
                      <span>
                        {cards_6[2].split("/")[0]} <br />{" "}
                        {cards_6[2].split("/")[1]}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`grid-5 grid-4 grid-3 grid-2 boxes wow fadeInUp`}
                    data-wow-duration="0.5s"
                    data-wow-delay="1.3s"
                  >
                    <div className={`inside_boxes bottom`}>
                      <img src={MobileApp} alt="" />
                      <span>
                        {cards_6[3].split(" ")[0]} <br />{" "}
                        {cards_6[3].split(" ")[1]}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`grid-5 grid-4 grid-3 grid-2 boxes wow fadeInUp`}
                    data-wow-duration="0.5s"
                    data-wow-delay="1.5s"
                  >
                    <div className={`inside_boxes bottom`}>
                      <img src={QAManual} alt="" />
                      <span>
                        {cards_6[4].split("&")[0]} & <br />{" "}
                        {cards_6[4].split("&")[1]}
                      </span>
                    </div>
                  </div>
                  <div
                    className={`grid-5 grid-4 grid-3 grid-2 boxes btn-wrapper-boxe wow fadeInUp`}
                    data-wow-duration="0.5s"
                    data-wow-delay="1.5s"
                  >
                    <div className={`inside_boxes bottom`}>
                      <div className="btn-wrapper-mobile">
                        <Link to="#">{home_third_section_button_text}</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/*  */}
          {/* <div style={{ paddingTop: 0 }} className="second-section">
            <div className="container">
              <div
                id="video"
                className={`grid-1 video_box wow fadeInUp`}
                data-wow-duration="0.5s"
                data-wow-delay="1.7s"
              >
                <video autoPlay muted controls preload="metadata">
                  <source src={timeframe} />
                </video>
              </div>
            </div>
          </div> */}
          <div className="second-section client">
            <div className="container-fluid">
              <div className="container">
                <Clients />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withRouter(withTranslation()(SecondSection))
