import React, { useState } from 'react'
import './bigSquare.css'
import earth from '../../../assets/img/aboutImages/earthHat.svg'
import tree from '../../../assets/img/aboutImages/tree.svg'
import agriculture from '../../../assets/img/aboutImages/agriculture.svg'
import atom from '../../../assets/img/aboutImages/atom.svg'
import nurture from '../../../assets/img/aboutImages/nurturing.svg'
import shopping from '../../../assets/img/aboutImages/shopping-cart.svg'
import { company_third_section_cards } from '../../../locales/translation'
import { AiOutlineClose } from 'react-icons/ai'
import { getLanguageState } from '../../../store/reducers/languageReducer'
import { useSelector } from 'react-redux'
import { withTranslation } from 'react-i18next'

function BigSquare(props) {
  const [classIndex, setClassIndex] = useState()
  const languageState = useSelector(getLanguageState)
  const { t } = props

  const bigSquareSquares = [
    {
      img: earth,
      text: t('eLearaning'),
      description: t('eLearaningDsc1'),
    },
    {
      img: tree,
      text: t('telecomunication'),
      description: t('telecomunicationDsc2'),
    },
    {
      img: agriculture,
      text: t('agriculture'),
      description: t('agricultureDsc3'),
    },
    {
      img: shopping,
      text: t('eCommerce'),
      description: t('eCommerceDsc4'),
    },
    {
      img: nurture,
      text: t('humanResources'),
      description: t('humanResourcesDsc5'),
    },
    {
      img: atom,
      text: t('businessProcessAutomation'),
      description: t('businessProcessAutomationDsc6'),
    },
  ]

  return (
    <div className="bigCon">
      <div className="bigSquareDiv">
        <div className="bigSquareDivSquares">
          {bigSquareSquares.map((item, index) => {
            return (
              <div
                onClick={() => {
                  classIndex !== index && setClassIndex(index)
                }}
                key={index}
                className={`bigSquareContainer div${index + 1} ${
                  classIndex === index && 'animation2'
                }`}
              >
                {classIndex === index && (
                  <div
                    onClick={() => setClassIndex(null)}
                    className="closeBigCard"
                    style={{ zIndex: 121 }}
                  >
                    <AiOutlineClose color="white" />
                  </div>
                )}
                <img alt={`${item} square`} src={item.img} />
                <p
                  style={{
                    marginTop: '15px',
                    fontFamily: 'ligh-font',
                  }}
                >
                  {item.text}
                </p>
                <p className="moreDetailsBigSquare">More Details</p>
                {classIndex === index && (
                  <p
                    className={`divDescriptionCards ${
                      classIndex === index && 'animation2'
                    }`}
                  >
                    {classIndex === index && item.description}
                  </p>
                )}

                <div
                  className={`growLinearColor div${index + 1} ${
                    classIndex === index && 'animation2'
                  }`}
                ></div>
              </div>
            )
          })}
        </div>
        <div
          style={{
            backgroundColor: `rgba(0, 3, 71, 0.4)`,
          }}
          className="bigSquareLayer"
        ></div>
      </div>
    </div>
  )
}

export default withTranslation()(BigSquare)
