import React, { Component } from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import apiService from '../../services/apiService'
import renderHTML from 'react-render-html'
import moment from 'moment'
import Loader from '../loader/loader'
import { withRouter } from 'react-router-dom'
import { FaFacebookF, FaLinkedinIn } from 'react-icons/fa'
import './blog.css'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import {
  inside_blog_tags,
  inside_blog_related_title,
  blog_read_more,
} from '../../locales/translation'
import BeforeAfterSlider from 'react-before-after-slider'
import BlogContent from './blogContent'
import { withTranslation } from 'react-i18next'

class InsideBlog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.match.params.title,
      blog: {},
      blogs: [],
      loading: true,
    }
  }

  componentDidMount() {
    document.body.classList.add('white-menu')
    const { title } = this.state
    apiService.getBlogByTitle(title).then(({ data: response }) => {
      this.setState({
        blog: response[0],
        loading: false,
      })
      const scrollPos = sessionStorage.getItem('scrollPos')
      if (scrollPos) {
        window.scroll({
          top: scrollPos,
          behavior: 'smooth',
        })
      }
    })
    if (this.props.language) {
      apiService.getBlogs(this.props.language).then(({ data: response }) => {
        this.setState({
          blogs: response,
        })
      })
    }
  }

  // componentWillReceiveProps(newProps) {
  //   let title = newProps.match.params.title
  //   apiService.getBlogByTitle(title).then(({ data: response }) => {
  //     this.setState({
  //       blog: response,
  //     })
  //   })
  //   if (this.props.language) {
  //     apiService.getBlogs(this.props.language).then(({ data: response }) => {
  //       this.setState({
  //         blogs: response,
  //       })
  //     })
  //   }
  //   this.forceUpdate()
  // }

  componentWillUnmount() {
    document.body.classList.remove('white-menu')
  }

  onDescriptionClick = (title) => {
    this.props.history.push(`/blog/${title}`)
  }

  changeBlog = (num) => {
    this.setState({ loading: true })
    this.props.history.push(`/blog/${this.state.blogs[num].title}`)
    this.setState({ loading: false })
  }

  render() {
    const { blog, blogs, loading } = this.state
    const language = this.props.language
    const { t } = this.props
    console.log(blog)
    return (
      <React.Fragment>
        <Helmet>
          <meta
            id="url"
            property="og:url"
            content={`https://tframe.de/work/${blog.title}`}
          />
          <meta id="type" property="og:type" content="article" />
          <meta id="title" property="og:title" content={blog.title} />
          <meta
            id="description"
            property="og:description"
            content={blog.description}
          />
          <meta id="image" property="og:image" content={blog.image} />
        </Helmet>
        <Loader loading={loading} />
        <div style={{ paddingTop: '80px' }} className={`inside-project`}>
          <div className={`first-container`}>
            <div className="blogImageContainer">
              {blog.image && (
                <img
                  alt="blogBigImage"
                  className="blogBigImage"
                  src={blog.image}
                />
              )}
              <div className="blogImageContainerText">
                <div>
                  <p style={{ fontFamily: 'ligh-font' }} className="blogP">
                    {moment(blog.date_posted).format('DD MMM,YYYY')}
                  </p>
                  <h1 className="blogH">
                    {language === 'en' ? blog.title : blog.de_title}
                  </h1>
                </div>
              </div>
            </div>
            <div className={`second-container extra-container`}>
              <BlogContent blog={blog} blogs={blogs} />
              <div className={`container`}>
                <div className={`projects`}>
                  <span
                    style={{ justifyContent: 'left' }}
                    className={`inside-project-related-title`}
                  >
                    {t('realtedBlogs')}
                  </span>
                  <div className={`grid inside_small`}>
                    {blogs[1] && (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.changeBlog(0)}
                        className={`grid-3 grid-2 grid-1 inside_blog-small`}
                      >
                        <div className={`insid_blog_first_ img-part`}>
                          <img
                            className={`small-img`}
                            src={blogs[1].image}
                            alt=""
                          />
                          <div className="blogLayerDiv">
                            <div className="blogLayer">
                              <p
                                style={{
                                  fontFamily: 'ligh-font',
                                  fontSize: '14px',
                                }}
                              >
                                {t('blogReadMore')}
                              </p>
                            </div>{' '}
                          </div>
                        </div>
                        <div className={`insid_blog_first_ text-part`}>
                          <span className={`date`}>
                            {moment(blogs[1].date_posted).format('DD MMM')} 2020
                          </span>
                          <div>
                            <span className={`small-title`}>
                              {language === 'en'
                                ? blogs[1].description
                                : blogs[1].de_description}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {blogs[2] && (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.changeBlog(1)}
                        className={`grid-3 grid-2 grid-1 inside_blog-small`}
                      >
                        <div className={`insid_blog_first_ img-part`}>
                          <img
                            className={`small-img`}
                            src={blogs[2].image}
                            alt=""
                          />
                          <div className="blogLayerDiv">
                            <div className="blogLayer">
                              <p style={{ fontFamily: 'ligh-font' }}>
                                read more
                              </p>
                            </div>{' '}
                          </div>
                        </div>
                        <div className={`insid_blog_first_ text-part`}>
                          <span className={`date`}>
                            {moment(blogs[2].date_posted).format('DD MMM YYYY')}
                          </span>
                          <div>
                            <span className={`small-title`}>
                              {language === 'en'
                                ? blogs[2].description
                                : blogs[2].de_description}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {blogs[3] && (
                      <div
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.changeBlog(2)}
                        className={`grid-3 grid-2 grid-1 inside_blog-small`}
                      >
                        <div className={`insid_blog_first_ img-part`}>
                          <img
                            className={`small-img`}
                            src={blogs[3].image}
                            alt=""
                          />
                          <div className="blogLayerDiv">
                            <div className="blogLayer">
                              <p style={{ fontFamily: 'ligh-font' }}>
                                read more
                              </p>
                            </div>{' '}
                          </div>
                        </div>
                        <div className={`insid_blog_first_ text-part`}>
                          <span className={`date colorBlog`}>
                            {moment(blogs[3].date_posted).format('DD MMM YYYY')}
                          </span>
                          <div>
                            <span className={`small-title colorBlog`}>
                              {language === 'en'
                                ? blogs[3].description
                                : blogs[3].de_description}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { language: state.language.language }
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, null)(InsideBlog)),
)
