import React, { Component } from "react";
import FirstSection from "./firstSection";
import ProcessDevelopment from "./process";
import OurTeam from "./our-team";
import LDF from "./ldf";
import PCRD from "./pcrd";
import SecondSection from "./secondSection";

class AboutUs extends Component {
  componentDidMount() {
    document.body.classList.add("white-menu");
    const scrollPos = sessionStorage.getItem("scrollPos");
    if (scrollPos) {
      window.scroll({
        top: scrollPos,
        behavior: "smooth",
      });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("white-menu");
  }

  render() {
    return (
      <React.Fragment>
        <div
          style={{
            height: "auto",
          }}
          className={`first-section`}
        >
          <FirstSection />
        </div>
        <div className={`first-container`}>
          <SecondSection />
        </div>
        <div className={`container `}></div>
        <ProcessDevelopment />
        <OurTeam />
        <div className="backcolorabout">
          <div className="container paddingNone">
            <LDF />
            <PCRD />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default AboutUs;
