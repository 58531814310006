import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import apiService from '../../services/apiService'
import moment from 'moment'
import Loader from '../loader/loader'
import {
  blog_title,
  blog_description,
  blog_read_more,
} from '../../locales/translation'
import { connect } from 'react-redux'
import lines from '../../assets/img/lines.svg'
import RenderSmoothImage from 'render-smooth-image-react'
import { withTranslation } from 'react-i18next'

class Blog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      blogs: [],
      loading: true,
    }
  }

  componentDidMount() {
    document.body.classList.add('white-menu')
    if (this.props.language) {
      apiService.getBlogs(this.props.language).then(({ data: response }) => {
        let sortedArray = response.slice().sort((a, b) => {
          return (
            new Date(
              moment(b.date_posted)
                .format('L')
                .replace('/', '-')
                .replace('/', '-'),
            ) -
            new Date(
              moment(a.date_posted)
                .format('L')
                .replace('/', '-')
                .replace('/', '-'),
            )
          )
        })

        this.setState({
          blogs: sortedArray,
          loading: false,
        })
        const scrollPos = sessionStorage.getItem('scrollPos')
        if (scrollPos) {
          window.scroll({
            top: scrollPos,
            behavior: 'smooth',
          })
        }
      })
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('white-menu')
  }

  render() {
    const { blogs, loading } = this.state
    const language = this.props.language
    const { t } = this.props
    console.log(blogs)
    return (
      <React.Fragment>
        <div className="lines-bg"></div>
        <Loader loading={loading} />
        <div className={`projects blogs`}>
          <div className={`first-container`}>
            <div className={`second-container extra-container`}>
              <div className={`container`}>
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className={`big-title  color blog`}
                >
                  <img src={lines} alt="" />
                  <span className="h2Tags">{t('latestBlogs')}</span>
                </div>
                <div className={`grid `}>
                  <div className={`grid-2 grid-1 inside_grid`}>
                    <div className={`inside_grid__`}>
                      <span
                        className="pTag"
                        style={{
                          color: '#2D2D40',
                          fontFamily: 'ligh-font',
                          fontSize: '18px',
                        }}
                      >
                        {t('blogDescription')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className={`blog`}>
                  <div className={`grid pleft15 inside_small`}>
                    {blogs.map((element, index) => {
                      return (
                        <div
                          key={element + '-' + index}
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            this.props.history.push(`/blog/${element.title}`)
                          }
                          className={`grid-3 grid-2 grid-1 inside_blog-small`}
                        >
                          <div className={`insid_blog_first_ img-part`}>
                            <RenderSmoothImage
                              src={element.image}
                              alt="alternate-text"
                            />
                            <div className="blogLayerDiv">
                              <div className="blogLayer">
                                <p
                                  style={{
                                    fontFamily: 'ligh-font',
                                    fontSize: '14px',
                                  }}
                                >
                                  {t('blogReadMore')}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className={`insid_blog_first_ text-part`}>
                            <span className={`date`}>
                              {moment(element.date_posted).format(
                                'DD MMM YYYY',
                              )}
                            </span>
                            <Link>
                              <span className={`small-title`}>{`${
                                language === 'en'
                                  ? element.title
                                  : element.de_title
                              }`}</span>
                            </Link>
                          </div>
                          <div
                            style={{
                              marginTop: 5,
                            }}
                            className="bottom-border"
                          ></div>
                        </div>
                      )
                    })}
                  </div>
                  {/* <Link className="see-more-btn hoverUp up" to="#">
                    Load More
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return { language: state.language.language }
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, null)(Blog)),
)
