import React, { useState, useEffect } from 'react'
import './contact.css'
import { toast } from 'react-toastify'
import apiService from '../../services/apiService'
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa'
import GoogleMapReact from 'google-map-react'

import Loader from '../loader/loader'
import {
  placeholders_4,
  contact_title_big,
  contact_title_small,
  contact_errors,
  contact_button_text,
  contact_contact_us_title,
  contact_location_title,
  contact_location_data,
  contact_link,
  contact_social_title,
} from '../../locales/translation'
import { withTranslation } from 'react-i18next'

const Contact = (props) => {
  const [nameValue, setNameValue] = useState('')
  const [subjectValue, setSubjectValue] = useState('')
  const [emailValue, setEmailValue] = useState('')
  const [messageValue, setMessageValue] = useState('')
  const [sendMessageLoading, setSendMessageLoading] = useState(false)
  const [nameValid, setNameValid] = useState(false)
  const [subjectValid, setSubjectValid] = useState(false)
  const [emailValid, setEmailValid] = useState(false)
  const [messageValid, setMessageValid] = useState(false)
  const [formValid, setFormValid] = useState(false)
  const [loading, setLoading] = useState(true)
  const location = {
    address: 'Tech Frame',
    lat: 42.648376777403904,
    lng: 21.16985856036112,
  }
  const renderMarkers = (map, maps) => {
    new maps.Marker({
      position: {
        lat: 42.64820710546516,
        lng: 21.16976035480603,
      },
      map,
      title: 'Tech Frame',
    })
    new maps.Marker({
      position: {
        lat: 48.09392243665272,
        lng: 11.517780197963392,
      },
      map,
      title: 'Tech Frame',
    })
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 500)
    document.body.classList.add('white-menu')
    return () => {
      document.body.classList.remove('white-menu')
    }
  }, [])

  const sendMessage = () => {
    setSendMessageLoading(true)
    if (
      nameValue.length < 2 ||
      subjectValue.length < 5 ||
      !validateEmail(emailValue) ||
      messageValue.length < 10
    ) {
      setFormValid(true)
      setSendMessageLoading(false)

      return
    } else {
      // const data = {
      //   name: nameValue,
      //   subject: subjectValue,
      //   email: emailValue,
      //   message: messageValue,
      // };
      const data = {
        first_name: nameValue,
        last_name: subjectValue,
        email: emailValue,
        message: messageValue,
      }

      apiService
        .postContactUs(data)
        .then(() => {
          toast.success(
            'Thank you for contacting us. We will contact you as soon as possible!',
          )
          setNameValue('')
          setSubjectValue('')
          setEmailValue('')
          setMessageValue('')
          setFormValid(false)
        })
        .catch((err) => {
          toast.error('Sending Message Failed')
        })
      setSendMessageLoading(false)
    }
  }

  const { t } = props

  return (
    <div className="contactFullPage">
      <Loader loading={loading} />
      <div
        style={{
          width: '100%',
          borderTop: '1px solid #DEDEDE',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className="containerContact">
          <div className="mainContentContainer">
            <div className="contactFullPageLeft">
              <div className="contactFullPageLeftText">
                <h1 className="h1FullPageContact">
                  {t('letsStart')}{' '}
                  <span
                    style={{ color: '#1CA8FC' }}
                    className="h1FullPageContact"
                  >
                    {t('working')}
                  </span>{' '}
                  {t('together')}
                </h1>
              </div>
              <div className="contactFullPageLeftForm">
                <h1 className="h2FullPageContact">{t('needsDiscuss')}</h1>
                <div className="formContactPageDiv">
                  <div className="twoinputsDiv">
                    <div style={{ width: '100%' }} className="firstInput">
                      <input
                        onChange={(e) => {
                          setNameValue(e.target.value)
                        }}
                        value={nameValue}
                        placeholder={t('fullName')}
                        className="fullContactInput"
                        type="text"
                        onFocus={() => setNameValid(false)}
                        onBlur={() => {
                          if (nameValue.length < 2) {
                            setNameValid(true)
                          }
                        }}
                      />
                      {nameValid && (
                        <p
                          style={{
                            color: 'red',
                            fontSize: '14px',
                            fontFamily: 'ligh-font',
                          }}
                        >
                          {t('fullNameMustBe')}
                        </p>
                      )}
                    </div>
                    <div style={{ width: '100%' }}>
                      <input
                        onChange={(e) => {
                          setEmailValue(e.target.value)
                        }}
                        value={emailValue}
                        placeholder={t('email')}
                        className="fullContactInput"
                        type="text"
                        onFocus={() => setEmailValid(false)}
                        onBlur={() => {
                          if (!validateEmail(emailValue)) {
                            setEmailValid(true)
                          }
                        }}
                      />
                      {emailValid && (
                        <p
                          style={{
                            color: 'red',
                            fontSize: '14px',
                            fontFamily: 'ligh-font',
                          }}
                        >
                          {t('enterValidEmail')}
                        </p>
                      )}
                    </div>
                  </div>

                  <input
                    onChange={(e) => {
                      setSubjectValue(e.target.value)
                    }}
                    value={subjectValue}
                    placeholder={t('subject')}
                    className="fullContactInput"
                    type="text"
                    onFocus={() => setSubjectValid(false)}
                    onBlur={() => {
                      if (subjectValue.length < 5) {
                        setSubjectValid(true)
                      }
                    }}
                  />
                  {subjectValid && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '14px',
                        fontFamily: 'ligh-font',
                      }}
                    >
                      {t('subjectMustBe')}
                    </p>
                  )}

                  <textarea
                    onChange={(e) => {
                      setMessageValue(e.target.value)
                    }}
                    value={messageValue}
                    style={{
                      paddingBottom: '100px',
                      paddingTop: '10px',
                      resize: 'none',
                    }}
                    placeholder={t('message')}
                    className="fullContactInput"
                    onFocus={() => setMessageValid(false)}
                    onBlur={() => {
                      if (messageValue.length < 10) {
                        setMessageValid(true)
                      }
                    }}
                  />
                  {messageValid && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '14px',
                        fontFamily: 'ligh-font',
                        marginBottom: '10px',
                      }}
                    >
                      {t('messageMustbe')}
                    </p>
                  )}
                  {formValid && (
                    <p
                      style={{
                        color: 'red',
                        fontSize: '14px',
                        fontFamily: 'ligh-font',
                        marginBottom: '10px',
                      }}
                    >
                      {t('fieldsAreNotValid')}
                    </p>
                  )}

                  <div className="footerFrom">
                    <button
                      onClick={sendMessage}
                      className="fullContactButton hoverUp up"
                    >
                      {sendMessageLoading ? 'Loading...' : t('sendMessage')}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="contactFullPageRight">
              <h1 className="h1FullPageContact">{t('generalInquiries')}</h1>
              <div className="contactFullPageRightPart1">
                <div className="contactFullPageRightPart1General">
                  <h1
                    className="h2FullPageContact"
                    style={{ marginBottom: 10 }}
                  >
                    Email
                  </h1>
                  <pre className="pFullContact">info@tframe.de</pre>
                </div>
                <div className="contactFullPageRightPart1Location">
                  <h1
                    style={{ marginBottom: 10, marginTop: 20 }}
                    className="h2FullPageContact"
                  >
                    {t('location')}
                  </h1>
                  <div className='locations' >
                    <pre
                      className="pFullContact"
                      style={{
                        marginBottom: 10,
                        lineHeight: 1.45,
                      }}
                    >
                      <b>{t('contactLocationDe1')}</b>
                      <br />
                      {t('contactLocationDe2')}
                      <br />
                      {t('contactLocationDe3')}
                    </pre>
                    <pre
                      className="pFullContact"
                      style={{
                        marginBottom: 10,
                        lineHeight: 1.45,
                      }}
                    >
                      <b>{t('contactLocation0')}</b>
                      <br />
                      {t('contactLocation1')}
                      <br />
                      {t('contactLocation2')}
                      <br />
                      {t('contactLocation3')}
                    </pre>

                  </div>
                  <a
                    className="pFullContact"
                    style={{ color: '#1CA8FC', textDecoration: 'underline' }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.google.com/maps/dir/42.6482479,21.1056539/42.6482189,21.1698325/@42.6490357,21.1668223,17.52z/data=!4m4!4m3!1m1!4e1!1m0"
                  >
                    {t('getLocation')}
                  </a>
                </div>
              </div>
              <div className="contactFullPageRightPart2">
                <h1
                  style={{ marginBottom: 10, marginTop: 20 }}
                  className="h2FullPageContact"
                >
                  {t('follow')}
                </h1>
                <div style={{ display: 'flex' }}>
                  <a
                    className="getLocationContact reactIconHoverDiv"
                    target="_blank"
                    href="https://www.facebook.com/tframelabs"
                    rel="noopener noreferrer"
                  >
                    <FaFacebookF className="reactIconHover" />
                  </a>
                  <a
                    style={{ borderLeft: 'none', borderRight: 'none' }}
                    className="getLocationContact reactIconHoverDiv"
                    target="_blank"
                    href="https://www.instagram.com/techframelabs/"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram className="reactIconHover" />
                  </a>
                  <a
                    className="getLocationContact reactIconHoverDiv"
                    target="_blank"
                    href="https://www.linkedin.com/company/techframelabs/"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedinIn className="reactIconHover" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mapContainer">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: 'AIzaSyCfzKmp1ItoTqUJgAx2IYpRblvNMSne9e8',
              }}
              defaultCenter={location}
              defaultZoom={5}
              options={(map) => ({ mapTypeId: map.MapTypeId.ROADMAP })}
              onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
              yesIWantToUseGoogleMapApiInternals={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(Contact)
