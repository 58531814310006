import { CHANGE_LANGUAGE, GET_LANGUAGE } from '../actions/language'

const initialState = {
  language: 'en',
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LANGUAGE:
      return {
        language: action.language,
        // language: "en"
      }

    case CHANGE_LANGUAGE:
      return {
        language: action.language,
        // language: "en"
      }

    default:
      return state
  }
}

export default reducer

export const getLanguageState = (state) => state.language
