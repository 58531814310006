import React, { Component } from 'react'
import lines from '../../assets/img/lines.svg'
import {
  company_fourth_section_title,
  company_fourth_section_left_title,
  company_fourth_section_left_cards_titles,
  company_fourth_section_left_cards_descriptions,
  company_fourth_section_right_title,
  company_fourth_section_right_cards_titles,
  company_fourth_section_right_cards_descriptions,
} from '../../locales/translation'
import Big1 from '../../assets/img/1.svg'
import Big2 from '../../assets/img/2.svg'
import { withTranslation } from 'react-i18next'

class ProcessDevelopment extends Component {
  render() {
    const { t } = this.props

    return (
      <React.Fragment>
        <div style={{ marginBottom: 0 }} className={`process agileDiv`}>
          <div className="container">
            <div
              style={{ marginBottom: 0, paddingLeft: 0 }}
              className={`big-title color  paddingNone whoWeAreDivTitle`}
            >
              <img className="linesMobile" src={lines} alt="" />
              <div>
                <h2
                  className="h2Tags"
                  style={{
                    marginLeft: 10,
                    lineHeight: 1.4,
                    fontSize: 30,
                    color: '#000347',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('agileDevelopment')}{' '}
                </h2>
                <h2
                  className="h2Tags"
                  style={{
                    marginLeft: 10,
                    lineHeight: 1.4,
                    fontSize: 30,
                    color: '#000347',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('process')}
                </h2>
              </div>
            </div>

            <div className="companyAgileBigImagesDiv">
              <div className="agile-wrapper">
                <div className="agile-wrapper-undertitle">
                  <h2
                    className="h2Tags"
                    style={{
                      marginLeft: 10,
                      lineHeight: 1.4,
                      fontSize: 30,
                      color: '#000347',
                      textTransform: 'uppercase',
                    }}
                  >
                    {t('projectBased')}
                  </h2>
                  <p
                    style={{
                      fontWeight: 300,
                      marginLeft: 10,
                      lineHeight: 1.4,
                      fontSize: 14,
                      color: '#000347',
                      textTransform: 'uppercase',
                    }}
                  >
                    {t('cooperationMethodology')}
                  </p>
                </div>
                <div className="agile-wrapper-textbox">
                  <h2>{t('develop')}</h2>
                  <p className="ptags" style={{ fontSize: 12 }}>
                    {t('theTeamIsFormed')}
                  </p>
                </div>
                <div className="agile-wrapper-textbox">
                  <h2>{t('deliver')}</h2>
                  <p className="ptags" style={{ fontSize: 12 }}>
                    {t('theClientIsDelivered')}
                  </p>
                </div>
              </div>
              {/* <div style={{width: '100%', backgroundColor: 'red', height:'300px'}}>

              </div> */}
              <img src={Big2} alt="rightBigim" className="companyAglieImg" />
              <div className="agile-wrapper">
                <div className="agile-wrapper-textbox">
                  <h2>{t('plan')}</h2>
                  <p className="ptags" style={{ fontSize: 12 }}>
                    {t('clientProvide')}
                  </p>
                </div>
                <div className="agile-wrapper-textbox">
                  <h2>{t('stabilize')}</h2>
                  <p className="ptags" style={{ fontSize: 12 }}>
                    {t('tFrameQaTeam')}
                  </p>
                </div>
                {/* <div className="agile-wrapper-textbox">
                  <h2>
                    {company_fourth_section_left_cards_titles[2]}
                  </h2>
                  <p className="ptags" style={{fontSize: 14}}>
                    {company_fourth_section_left_cards_descriptions[2]}
                  </p>
              </div> */}
              </div>
              <div className="agile-wrapper">
                <div className="agile-wrapper-undertitle">
                  <h2
                    className="h2Tags"
                    style={{
                      marginLeft: 10,
                      lineHeight: 1.4,
                      fontSize: 30,
                      color: '#000347',
                      textTransform: 'uppercase',
                    }}
                  >
                    {t('companyFourthSectionRightTitle')}
                  </h2>
                </div>
                <div className="agile-wrapper-textbox">
                  <h2>{t('sprintPlanning')}</h2>
                  <p className="ptags" style={{ fontSize: 12 }}>
                    {t('projectManagerProvides')}
                  </p>
                </div>
                <div className="agile-wrapper-textbox">
                  <h2>{t('acceptanceTesting')}</h2>
                  <p className="ptags" style={{ fontSize: 12 }}>
                    {t('basedOnAcceptance')}
                  </p>
                </div>
              </div>
              {/* <div style={{width: '100%', backgroundColor: 'red', height:'300px'}}> */}
              <img src={Big1} alt="rightBigim" className="companyAglieImg" />
              <div className="agile-wrapper">
                <div className="agile-wrapper-textbox">
                  <h2>{t('goalDefinition')}</h2>
                  <p className="ptags" style={{ fontSize: 12 }}>
                    {t('initialScope')}
                  </p>
                </div>
                <div className="agile-wrapper-textbox">
                  <h2>{t('development')}</h2>
                  <p className="ptags" style={{ fontSize: 12 }}>
                    {t('teamCarries')}
                  </p>
                </div>
              </div>

              {/* <img src={Big2} alt="rightBigim" className='companyAglieImg' /> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(ProcessDevelopment)
