import React from 'react'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import apiService from '../../services/apiService'
import renderHTML from 'react-render-html'
import Input from '../../common/form/input'
import Form from '../../common/form/form'
import File from '../../common/form/file'
import { toast } from 'react-toastify'
import Loader from '../loader/loader'
import {
  benefits_12,
  carrer_apply,
  carrer_apply_name,
  carrer_apply_cv,
  carrer_apply_email,
  carrer_apply_number,
  carrer_apply_submit,
  carrer_apply_sur_name,
  carrer_apply_choose,
  carrer_apply_title,
  carrer_final_section_title,
} from '../../locales/translation'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Benefits from './benefits'
import { withTranslation } from 'react-i18next'

class InsideCareer extends Form {
  constructor(props) {
    super(props)
    this.state = {
      title: props.match.params.title,
      job: {},
      data: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        image: [],
        job_id: '',
      },
      loading: true,
      isSubmitting: false,
    }
  }

  componentDidMount() {
    let { title, data } = this.state
    document.body.classList.add('white-menu')
    apiService.getJobByTitle(title).then(({ data: response }) => {
      data.job_id = response.id
      this.setState({
        job: response,
        data,
        loading: false,
      })
      const scrollPos = sessionStorage.getItem('scrollPos')
      if (scrollPos) {
        window.scroll({
          top: scrollPos,
          behavior: 'smooth',
        })
      }
    })
  }

  componentWillUnmount() {
    document.body.classList.remove('white-menu')
    document.documentElement.style.overflow = 'auto'
  }

  toggleApply = () => {
    const { onApply } = this.state
    this.setState({
      onApply: !onApply,
    })
    if (!onApply) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }
  }

  submitForm() {
    const { data, job } = this.state
    this.setState({
      isSubmitting: true,
    })
    apiService.postApply(data).then(() => {
      toast.success('Your application has been submitted. GOOD LUCK!')
      this.setState({
        onApply: !this.state.onApply,
        data: {
          first_name: '',
          last_name: '',
          email: '',
          phone: '',
          image: [],
          job_id: job.id,
        },
      })
      this.validator.hideMessages()
      this.forceUpdate()
      this.setState({
        isSubmitting: false,
      })
    })
  }

  render() {
    const { onApply, job, data, loading, isSubmitting } = this.state
    const { first_name, last_name, email, phone, image } = data
    const { t } = this.props
    return (
      <React.Fragment>
        <Loader loading={loading} />
        {onApply && (
          <div
            style={{
              width: '100%',
              height: '100%',
              zIndex: 10,
              position: 'fixed',
              top: 0,
              left: 0,
              backgroundColor: 'rgba(0,0,0,0.3)',
            }}
            onClick={this.toggleApply}
          />
        )}
        <div className="lines-bg" />
        <div className={`inside-project`}>
          <div className={`first-container`}>
            <div className={`second-container extra-container`}>
              <div className={`container extra-container`}>
                <img className={`inside-career-img`} src={job?.image} alt="" />
                <span className={`inside-career-title`}>
                  {this.props.language === 'en' ? job.title : job.de_title}
                </span>
                <div className={`open_position__right_bottom`}>
                  <div
                    className={`open_position__right_bottom_ inside-career-location`}
                  >
                    <img src="../assets/img/location.svg" alt="" />
                    <span>{job.location}</span>
                  </div>
                  <div className={`open_position__right_bottom_ time-ago`} />
                </div>
                <div
                  className={`text-editor ql-editor inside-career-editor`}
                  id={'editor'}
                >
                  {renderHTML(
                    `${
                      this.props.language === 'en'
                        ? job.content
                        : job.de_content
                    }`,
                  )}
                </div>
                <div className={`grid  mTop50 noPleft`}>
                  <Benefits />
                </div>
                <div className={`apply-now `} onClick={this.toggleApply}>
                  <span className={`apply-btn  hoverUp up`}>
                    {t('apply')}
                    <div className={`helper-div`} />
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={`form-apply`} is-active={`${!!onApply}`}>
            <div className={`form-apply_`}>
              <span className={`x`} onClick={this.toggleApply}>
                <span />
                <span />
              </span>
              <div className={`form-apply__`}>
                <span className={`inside-career-form-title`}>
                  {t('applyNow')}
                </span>
                <form
                  className={`contact-form career`}
                  onSubmit={this.handleSubmit}
                >
                  <div className={`username`}>
                    <label>{t('firstName')}</label>
                    <Input
                      onChange={this.handleChange}
                      name={'first_name'}
                      type={'text'}
                      id={'first_name'}
                      value={first_name}
                      validator={this.validator}
                      validations={'required|min:3|max:20'}
                      className={'name popup-input'}
                    />
                  </div>
                  <div className="username">
                    <label>{t('lastName')}</label>
                    <Input
                      onChange={this.handleChange}
                      name={'last_name'}
                      type={'text'}
                      id={'last_name'}
                      value={last_name}
                      validator={this.validator}
                      validations={'required|min:3|max:20'}
                      className={'popup-input last_name'}
                    />
                  </div>
                  <div className="file-phone-data">
                    <div className={`email`}>
                      <label>{t('email')}</label>
                      <Input
                        onChange={this.handleChange}
                        name={'email'}
                        type={'text'}
                        id={'email'}
                        value={email}
                        validator={this.validator}
                        validations={'required|email'}
                        className={'popup-input'}
                      />
                    </div>
                    <div className={`phone-number`}>
                      <label>{t('phoneNumber')}</label>
                      <Input
                        onChange={this.handleChange}
                        name={'phone'}
                        type={'text'}
                        id={'phone'}
                        value={phone}
                        validator={this.validator}
                        validations={'required'}
                        className={'popup-input'}
                      />
                    </div>
                  </div>
                  <div className={`file`}>
                    <label>{t('yourCv')}</label>
                    <File
                      onChange={this.handleChange}
                      name={'image'}
                      multiple={true}
                      id={'image'}
                      extradiv={t('chooseFile')}
                      value={image.filename}
                      filename={image.name}
                      validator={this.validator}
                      validations={'alpha_space'}
                      allowedTypes={'doc'}
                    />
                  </div>
                  <div className={`apply-now`}>
                    <button
                      disabled={isSubmitting}
                      style={{ cursor: 'pointer' }}
                      className={`primary remove_margin hoverUp up`}
                      type="submit"
                    >
                      {t('submitApplication')}
                      <div className={`helper-div`}></div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { language: state.language.language }
}
// export default connect(mapStateToProps, null)(withRouter(InsideCareer))

export default withRouter(
  withTranslation()(connect(mapStateToProps, null)(InsideCareer)),
)
