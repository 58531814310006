import React, { Fragment, useState } from 'react'
import './cards5.css'
import chem from '../../../assets/img/aboutImages/chem.svg'
import digi from '../../../assets/img/aboutImages/digi.svg'
import mobile from '../../../assets/img/aboutImages/mobile.svg'
import ui from '../../../assets/img/aboutImages/ui.svg'
import web from '../../../assets/img/aboutImages/web.svg'
import supp from '../../../assets/img/aboutImages/Support.svg'
import { cards_6, cards_description_6 } from '../../../locales/translation'
import { AiOutlineClose } from 'react-icons/ai'
import { withTranslation } from 'react-i18next'
import i18n from '../../../i18n'

const cards5Data = [
  {
    img: digi,
    text: i18n.t('digitalization'),
    description: i18n.t('cardsDescription1'),
  },
  {
    img: ui,
    text: i18n.t('uiUxDesign'),
    description: i18n.t('cardsDescription2'),
  },
  {
    img: web,
    text: i18n.t('webAppDev').replace('/', ''),
    description: i18n.t('cardsDescription3'),
  },
  {
    img: mobile,
    text: i18n.t('mobileDev'),
    description: i18n.t('cardsDescription4'),
  },
  {
    img: chem,
    text: i18n.t('qaManual'),
    description: i18n.t('cardsDescription5'),
  },
  {
    img: supp,
    text: i18n.t('techSupport'),
    description: i18n.t('cardsDescription6'),
  },
]

function Cards5(props) {
  const [classIndex, setClassIndex] = useState()
  const { t } = props

  return (
    <div className="cards5Div">
      {cards5Data.map((item, index) => {
        return (
          <Fragment key={item + '-' + index}>
            <div
              onClick={() => {
                classIndex !== index && setClassIndex(index)
              }}
              className={`cards5DivCard div${index + 1} ${
                classIndex === index && 'animation'
              }`}
            >
              {classIndex === index && (
                <div
                  onClick={() => setClassIndex(null)}
                  className="closeBigCard"
                  style={{ zIndex: 11 }}
                >
                  <AiOutlineClose />
                </div>
              )}
              <div className="card5Img">
                <img src={item.img} alt="img" />
              </div>
              <div className="card5Square">
                <p className="div5Title">{item.text}</p>
                <>
                  <p
                    className={`divDescriptionCards ${
                      classIndex === index && 'animation'
                    }`}
                  >
                    {classIndex === index
                      ? item.description
                      : `${item.description.slice(0, 60)}`}
                  </p>
                </>
              </div>
            </div>
          </Fragment>
        )
      })}
    </div>
  )
}

export default withTranslation()(Cards5)
