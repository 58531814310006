import React from "react";
import left from "../../assets/gif/1slash.svg";
import right from "../../assets/gif/2slash.svg";
import top from "../../assets/gif/t-kapela.svg";
import image from '../../assets/tframeLoaderB.png'
import RenderSmoothImage from "render-smooth-image-react";


const Loader = (props) => {
        let {loading} = props;
        // loading = true;
        return (
            <div className={`loaderContainer ${loading ? "d-block" : "d-none"}`}>
                <div className="loader">
                    {/*<div style={{width:'100%',height:'100%'}}>*/}
                    {/*<RenderSmoothImage src={image} alt="alternate-text" />*/}
                    {/*</div>*/}
                </div>
                <div className="loader-holder" style={{position: "relative"}}>
                    <div className="gifContainer">
                        <img src={top} alt="" className="gifHat"/>
                        <img src={left} alt="" className="slashLeft"/>
                        <img src={right} alt="" className="slashRight"/>
                    </div>
                </div>
            </div>
        )
    }
;

export default Loader;
