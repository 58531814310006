import React, { Component } from 'react'
import lines from '../../assets/img/lines.svg'
import apiService from '../../services/apiService'
import { company_final_section_title1 } from '../../locales/translation'
import tech from '../../assets/img/chess/tech-stack.jpg'
import { withTranslation } from 'react-i18next'

class LDF extends Component {
  constructor(props) {
    super(props)
    this.state = {
      programming_languages: [],
    }
  }

  componentDidMount() {
    apiService.getProgrammingLanguages().then(({ data: response }) => {
      this.setState({
        programming_languages: response,
      })
    })
  }

  render() {
    const { programming_languages } = this.state
    const { t } = this.props
    return (
      <React.Fragment>
        <div
          style={{
            marginBottom: 0,
          }}
          className={`ldf agileDiv`}
        >
          <div className="container">
            <div className={``}>
              <div className={`left-part mTop50`}>
                <div
                  style={{
                    marginBottom: 40,
                  }}
                  className="logosTopImgText whoWeAreDivTitle wrapMobile"
                >
                  <img alt="lines" className="linesMobile" src={lines} />
                  <div>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        textTransform: 'uppercase',
                        color: '#000347',
                      }}
                    >
                      {t('techStack')}
                    </h2>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        textTransform: 'uppercase',
                        color: '#000347',
                      }}
                    >
                      {t('infrastructure')}
                    </h2>
                  </div>
                </div>
                <div className={`grid`}>
                  {programming_languages.map((element, index) => (
                    <div
                      key={index}
                      className={`grid-4 grid-3 logos logosMobile`}
                    >
                      <img src={element.image} alt="" />
                    </div>
                  ))}
                </div>
              </div>
              <div className={`right-part mTop50`}>
                <img src={tech} alt="" />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(LDF)
