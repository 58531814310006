let language = localStorage.getItem('languageVal')

if (!language) {
  language = 'en'
}
// let language = "en";
//home page translation
export let home_banner_title =
  language === 'en'
    ? [
        'We do',
        'DIGITAL ',
        'TRANSFORMATION',
        'through multiscreen',
        'SMART SOLUTIONS',
      ]
    : ['Wir schaffen', 'DIGITALEN', 'WANDEL', 'durch MULTISCREEN-LÖSUNG']

export let home_first_section_title =
  language === 'en' ? 'TODAY, EVERYTHING IS' : 'HEUTE IST ALLES MÖGLICH!'
export let home_first_section_title1 =
  language === 'en' ? ' POSSIBLE!' : ' POSSIBLE!'
export let home_first_section_description1 =
  language === 'en'
    ? 'Many of our clients through the years had different needs in digitalization of business activities, processes and increasing efficiency. We build with the most innovative technologies to deliver the smartest solutions that yield better results in day to day operations, and we’re still improving.'
    : 'Viele unserer Kunden hatten in den vergangenen Jahren unterschiedliche Anforderungen bezüglich  der Digitalisierung von täglichen Geschäftsprozessen und der damit verbundenen Steigerung der Effizienz Wir setzen auf innovativste Technologien, um die bestmöglichste und für Ihr Unternehmen passende Lösungen zu liefern mit dem Ziel bessere Ergebnisse im Daily Business zu erzielen.'
export let home_first_section_description2 =
  language === 'en'
    ? 'Our requirement engineers and planning department is highly skilled in analyzing your operational processes, and by working closely together with your organization, many of those processes become digital - making these processes become more seamless and efficient.'
    : 'Unsere Ingenieure und Planungsabteilung verfügen über Expertise, Erfahrung und Augenmaß, wenn es darum geht, die Prozesse, Methoden oder Standards unserer Kunden zu analysieren und zu verbessern. Durch die enge Zusammenarbeit mit Ihrem Unternehmen werden viele dieser Prozesse digitalisiert, wodurch diese Prozesse nahtloser und effizienter werden.'

export let home_second_section_title =
  language === 'en' ? 'Our recent projects' : 'Unsere letzten projekte'
export let home_second_section_description =
  language === 'en'
    ? 'View some of the most successful projects and more by our specialists presented in this gallery. We work to make your business start effectively working for you.'
    : 'In unserer Gallerie finden Sie eine Auswahl unserer erfolgreichsten Projekte, die von unseren Expertenteam durchgeführt wurden.Wir arbeiten daran, dass Ihr Unternehmen effektiv für Sie arbeitet.'
export let home_second_section_button_text =
  language === 'en' ? 'All Projects' : 'All Projects'

export let home_third_section_title =
  language === 'en'
    ? ['Full range of software', 'engineering services']
    : ['ALLE BEREICHE VON SOFTWARE-ENGINEERING-DIENSTLEISTUNGE']
export let home_third_section_button_text =
  language === 'en' ? 'Company' : 'Unser Unternehmen'

export let home_final_section_title =
  language === 'en' ? 'Clients Testimonials' : 'Clients Testimonials'
export let home_final_section_description =
  language === 'en'
    ? [
        'Tech Frame has offices in Europe, United States and nearshore Center in',
        'Kosovo in order for us to work closely with our clients.',
      ]
    : [
        'Tech Frame has offices in Europe, United States and nearshore Center in',
        'Kosovo in order for us to work closely with our clients.',
      ]

export let home_final_section_testimonial_left =
  language === 'en'
    ? ['Clients', 'Projects', 'Countries']
    : ['Kunden', 'Projekte', 'Kunden']

//company page translation
export let company_banner_title =
  language === 'en'
    ? ['Become a true', 'innovation', 'partner to our clients']
    : ['Werden sie ein treuer', 'innovationspartner', 'für unsere kunden']

export let company_banner_description =
  language === 'en'
    ? 'We develop software and apps for our international clients, with auxiliary services like digital marketing and call center support. Tech Frame is capable of handling a product at any stage of its life cycle. Whether the client needs a brand new product, designed, developed, branded and rolled out, or they need help with existing products we can handle the task.'
    : 'Wir entwickeln Softwares und Apps mit Zusatzleistungenwie digitalem Marketing und Call-Center-Support für unsere internationalen Kunden. Ganz gleich ob der Kunde ein neues Produkt benötigt, das entworfen, entwickelt, gebrandet und eingeführt werden muss oder ein bereits bestehendes Produkt neu vermarkten möchte, Tech Frame weiss, wie man ein Produkt auf dem Markt aussichtsreich platzieren und verkaufen kann.'
export let company_banner_button_text =
  language === 'en' ? 'Company presentation' : 'Firmenpräsentation'

export let company_first_section_title =
  language === 'en' ? ['WHO', 'WE ARE'] : ['WER', 'SIND WIR']
export let company_first_section_description =
  language === 'en'
    ? [
        'Our team of 30+ engineers with different backgrounds, are eager to turn your business activities and processes to to cutting edge software, as well as building your entire organization’s digital ecosystem from the ground up. By analyzing your needs, and planning your digitalization, we can reshape every aspect of a modern enterprise.',
        'Tech Frame is a fully focused, digital transformation organization, that aims in leveraging the full extent of the possibilities offered by the most up-to-date technologies, in order bring their impact in a more innovative future.',
      ]
    : [
        'Unser Team besteht aus mehr als 30 Ingenieuren mit unterschiedlichem Hintergrund, die bestrebt sind, Ihre täglichen Geschäftsprozesse mit modernster Software effizienter zu gestalten. Wir unterstützen Sie also das digitale Ökosystem Ihres gesamten Unternehmens von Grund auf neu zu bauen. Durch die Analyse Ihrer Bedürfnisse und die Planung der Digitalisierung können wir Ihr Unternehmen bis ins kleinste Detail modernisierenund neu gestalten.',
        'Tech Frame fokusiert sich dabei auf die digitale Transformation, die darauf abzielt, die Möglichkeiten der neuesten Technologien voll auszuschöpfen und um so ihre Wirkung in einer innovativeren Zukunft zu entfalten.',
      ]

export let company_second_section_title =
  language === 'en' ? ['OUR', 'SERVICES'] : ['OUR', 'SERVICES']

export let company_third_section_title =
  language === 'en' ? ['INDUSTRY', 'EXPERTISE'] : ['BRANCHEN', 'EXPERTISE']
export let company_third_section_cards =
  language === 'en'
    ? [
        'E-Learning',
        'Telecomunication',
        'Agriculture',
        'E-Commerce',
        'Human Resources',
        'Business process automation',
      ]
    : [
        'E-Learning',
        'Telekommunikation',
        'Landwirtschaft',
        'E-Commerce',
        'Personalmanagement',
        'Automatisierung Geschäftsprozesse',
      ]

export let company_fourth_section_title =
  language === 'en'
    ? ['Agile Developement', 'Process']
    : ['AGILER', 'ENTWICKLUNGSPROZESS']
export let company_fourth_section_left_title =
  language === 'en'
    ? ['Project-Based', 'Cooperation Methodology']
    : ['Projektbasierte', 'Kooperationsmethoden']
export let company_fourth_section_left_cards_titles =
  language === 'en'
    ? ['Plan', 'Develop', 'Stabilize', 'Deliver']
    : ['Planung', 'Entwicklung', 'Stabilisierung', 'Lieferung']
export let company_fourth_section_left_cards_descriptions =
  language === 'en'
    ? [
        'Client provide us with specification document, brief or mock-ups. We provide planning, project budget with milestones along with acceptance criteria. After agreement is established we initiate development.',
        'The team is formed and assigned with Project Manager/Team Lead. The project is Carried out in a set of iterations and collaboration Process is coordinated with the client. While our Team carries out coding and integration, regular Builds are shared with the client.',
        'TECH FRAME QA team conducts testing of the product and issues correspondent test reports. Project Team proceeds with the project stabilization based on the QA documentation and provides the client with the release candidate.',
        'The client is delivered with the final product which is consequently verified according to the acceptance criteria. All the source code is passed to the client and the product is deployed to the client’s environment.',
      ]
    : [
        'Der Kunde stellt uns ein detailiertes Dokument, eine Kurzbeschreibung oder ein Geschäftsmodell zur Verfügung. Wir machen uns dann an die Planung, bestimmen das Projektbudget und legen die Meilensteine sowie Akzeptanzkriterien fest. Nach gemeinsamer Einigung, machen wir uns sofort an die Umsetzungdes Projekts.',
        'Das Team wird gebildet und einem Projektmanager / Teamleiter zugeteilt. Das Projekt wird in einer Reihe von iterativen Prozessen durchgeführt, welche zusammen mit den Kunden koordiniert werden. Während unser Team die Codierung und Integration durchführt, werden regelmäßige Builds (Prozess des Zusammenfügens von Programmteilen als auch die fertige Software-Version) mit dem Kunden geteilt.',
        'Das Team der Qualitätssicherung von TECH FRAME führt Tests durch und erstellt Testberichte für das entsprechende Projekt. Das Projektteam fährt mit der Projektstabilisierung auf der Grundlage der Dokumentation der Qualitätssicherung  fort und stellt dem Kunden die endgültige Version (release candidate) zur Verfügung.',
        'Der Kunde erhält das Endprodukt, das folglich anhand der Abnahmekriterien überprüft wird. Der gesamte Quellcode wird an den Kunden übergeben und das Produkt wird beim Kunden vor Ort bereitgestellt',
      ]

export let company_fourth_section_right_title =
  language === 'en' ? 'Agile Methodology' : 'Agile Methodik'
export let company_fourth_section_right_cards_titles =
  language === 'en'
    ? [
        'Goal Definition',
        'Sprint Planning',
        'Development',
        'Acceptance Testing',
      ]
    : ['Zieldefinition', 'Sprint-Planung', 'Entwicklung', 'Akzeptanztest']
export let company_fourth_section_right_cards_descriptions =
  language === 'en'
    ? [
        'Initial scope is investigated to align client’s expectations on deadlines, milestones, deliverables and team composition.',
        'Project Manager provides short term planning, setting up timeframe of the sprints and choosing tasks from the backlog to include in the current iteration.',
        'Team carries out implementation on the tasks, included in the current sprint along with developer testing.',
        'Based on the acceptance criteria, after finalizing all tasks, chosen for the iteration demo/gloomung meeting is held with the client to discuss current results and simultaneously plan next sprint.',
      ]
    : [
        'Der anfängliche Umfang wird erarbeitet, um die Erwartungen des Kunden an Termine, Meilensteine, Ergebnisse und Teamzusammensetzung zu treffen.',
        'Der Projektmanager organisiert eine kurzfristige Planung, legt den Zeitrahmen für die Sprints fest, wählt Aufgaben aus und benennt das von ihm gewünschte Sprint-Ziel und priorisiert die Einträge des Produkt-Backlogs im Sinn dieses Ziels.',
        'Das Entwicklungsteam führt die Implementierung der im aktuellen Sprint enthaltenen Aufgaben zusammen mit Entwicklertests durch.',
        'Basierend auf den zuvor festgelegten Kriterien wird nach Abschluss aller Aufgaben, ein Meeting mit dem Kunden abgehalten, um die aktuellen Ergebnisse zu besprechen und um gleichzeitig den nächsten Sprint zu planen.',
      ]

export let company_fifth_section_title =
  language === 'en' ? ['OUR', 'TEAM'] : ['UNSER', 'TEAM']
export let company_fifth_section_people =
  language === 'en'
    ? [
        'SOFTWARE ENGINEERS',
        'QUALITY ASSURANCE',
        'FINANCE & MANAGEMENT',
        'PROJECT MANAGERS',
        'UI/UX DESIGNERS',
      ]
    : [
        'SOFTWARE-INGENIEURE',
        'QUALITÄTSSICHERUNG',
        'FINANZEN & MANAGEMENT',
        'PROJEKTMANAGER',
        'UI/UX DESIGNER',
      ]

export let company_final_section_title1 =
  language === 'en' ? ['TECH STACK'] : ['TECH STACK']
export let company_final_section_title2 =
  language === 'en'
    ? ['INFRASTRUCTURE ', 'AS CODE']
    : ['INFRASTRUKTUR', ' AS CODE']

//works page translation
export let works_title = language === 'en' ? 'PROJECTS' : 'PROJECTS'
export let works_description1 =
  language === 'en'
    ? 'Rapid changes in consumer trends have caused the need for innovation and digital transformation for many industries worldwide.'
    : 'View some of the most successful projects and more by our specialists presented in this gallery. We work to make your business start effectively working for you.'
export let works_description2 =
  language === 'en'
    ? 'We as Techframe are constantly following the technology trends and innovations, and through a professional consulting team, we prepare and present you with a fully customized solution that follows the best practices of digital transformation for your company, organization. Some of the selected projects that we have done are as follows:'
    : 'Immer schnellere Veränderungen bei Verbrauchertrends erfordern einen hohen Grad an Innovation und digitale Transformation weltweit'
export let works_description2Mobile =
  language === 'en'
    ? 'Some of the selected projects that we have done are as follows:'
    : 'Immer schnellere Veränderungen bei Verbrauchertrends erfordern einen hohen Grad an Innovation und digitale Transformation weltweit'
export let inside_work_next =
  language === 'en' ? 'Next Project' : 'Next Project'
export let inside_work_prev =
  language === 'en' ? 'Prev Project' : 'Prev Project'
export let inside_work_details =
  language === 'en'
    ? ['Date', 'Client', 'Category']
    : ['Datum', 'Kunde', 'Kategorie']

//blog page translation
export let blog_title =
  language === 'en' ? 'LATEST BLOGS & NEWS' : 'LATEST BLOGS & NEWS'
export let blog_description =
  language === 'en'
    ? 'Get informed with lates Blog & News.'
    : 'Get informed with lates Blog & News.'
export let blog_read_more = language === 'en' ? 'read more' : 'read more'
export let inside_blog_tags = language === 'en' ? 'Tags' : 'Tags'
export let inside_blog_related_title =
  language === 'en' ? 'Related Blogs' : 'Related Blogs'

//carrer page translation
export let carrer_banner_title_small = language === 'en' ? 'Career' : 'KARRIERE'
export let carrer_banner_title_big =
  language === 'en'
    ? ['FRESH BLOOD IS', 'ALWAYS', 'WELCOMED']
    : ['WIR SUCHEN STETIG NACH TALENTEN', 'DIE NOCH BESSER SIND', 'ALS WIR']
export let carrer_banner_title_description =
  language === 'en'
    ? 'We are growing day by day, that’s why we need you to contribute in our brainstorming meetings and solve our problems.'
    : 'Unser Team wächst weiter und deshalb brauchen wir DICH, um bei unseren Brainstormings uns mit lösungsorientierten Ideen zu unterstützen.'

export let carrer_first_section_title =
  language === 'en' ? ['Open', 'Positions'] : ['Offene', 'Stellen']

export let carrer_final_section_title =
  language === 'en' ? ['Perks & ', 'Benefits'] : ['Unsere & ', 'Benefits']

export let carrer_apply = language === 'en' ? 'Apply' : 'Apply'
export let carrer_apply_title = language === 'en' ? 'Apply Now' : 'Apply Now'
export let carrer_apply_name = language === 'en' ? 'First Name' : 'First Name'
export let carrer_apply_sur_name = language === 'en' ? 'Last Name' : 'Last Name'
export let carrer_apply_email = language === 'en' ? 'Email' : 'Email'
export let carrer_apply_number =
  language === 'en' ? 'Phone Number' : 'Phone Number'
export let carrer_apply_cv = language === 'en' ? 'Your CV' : 'Your CV'
export let carrer_apply_submit =
  language === 'en' ? 'Submit Application' : 'Submit Application'
export let carrer_apply_choose =
  language === 'en' ? 'Choose File' : 'Choose File'

//contact page translation
export let contact_title_big =
  language === 'en'
    ? ['Let’s start', 'working', 'together']
    : ['Let’s start', 'working', 'together']
export let contact_title_small =
  language === 'en' ? "Let's discuss your needs!" : 'Let’s discuss your needs!'
export let contact_errors =
  language === 'en'
    ? [
        'Full Name must be longer than 2 letters!',
        'Subject must be longer than 5 letters!',
        'Please enter a valid email!',
        'Message must be longer than 10 letters!',
        'Fields are not valid !',
      ]
    : [
        'Full Name must be longer than 2 letters!',
        'Subject must be longer than 5 letters!',
        'Please enter a valid email!',
        'Message must be longer than 10 letters!',
        'Fields are not valid !',
      ]

export let contact_button_text =
  language === 'en' ? 'Send Message' : 'Send Message'
export let contact_contact_us_title =
  language === 'en' ? 'General Inquiries' : 'General Inquiries'
export let contact_location_title = language === 'en' ? 'Location' : 'Location'
export let contact_location_data =
  language === 'en'
    ? ['HQ: Agim Ramadani -1,', 'Bregu i Diellit 10000,', 'Pristina, Kosovo.']
    : ['HQ: Agim Ramadani -1,', 'Bregu i Diellit 10000,', 'Pristina, Kosovo.']
export let contact_link = language === 'en' ? 'Get Location' : 'Get Location'
export let contact_social_title = language === 'en' ? 'Follow' : 'Follow'

//header translation
export let header_titles =
  language === 'en'
    ? ['HOME', 'COMPANY', 'WORKS', 'BLOG', 'CAREER', 'CONTACT']
    : [
        'HOME',
        'UNSER UNTERNEHMEN',
        'UNSERE PROJEKTE',
        'BLOG',
        'KARRIERE',
        'KONTAKT',
      ]

//footer translation
export let footer_title =
  language === 'en'
    ? 'Let’s start working together'
    : 'Wir freuen uns auf eine Zusammenarbeit!'

//common translations
export let cards_6 =
  language === 'en'
    ? [
        'Digitalization',
        'UI/UX Design & Branding Services',
        'Web Application / Development',
        'Mobile Development',
        'QA Manual & Automation',
        'Tech Support',
      ]
    : [
        'Digitalisierung',
        'UI/UX Design & Branding Services',
        'WEB-Anwendungen',
        'App-Entwicklungen',
        'Digitalisierte Qualitätssicherung',
        'Technischer Support',
      ]

export let cards_description_6 =
  language === 'en'
    ? [
        'Rapid change of lifestyle trends has forced the automation of many communication and collaboration processes thus simplifying everything we do in our daily lives and works. From online orders and payments, up to production, distribution and client support and engagement. Our digital consultants’ team has developed many transformation projects for multiple companies in a wide spectrum of digitalization that has simplified many company operations, collaborations, and the way they do business.',
        'Digitalization has transformed not just objective operations, but also many industries that were considered more subjective and more engaged in matters of feel, see, believe, and trust. Brands that were developed in many physical forms in the past that required multiple physical engagements, now have transformed to a client’s online journey and engagement where the brand is build alongside the clients experience and recommendation.',
        'Staring from two-sided to more complex and multi-sided platforms and for different purposes including: matchmaking, employee collaboration, project management, procurement, academic and industrial level applications that have transformed the process of hundreds of SME’s and Corporations globally.',
        'Having into consideration that 90% of the time spend on mobile from mobile users is spend on applications, and that mobile form of buying and collaboration is mostly preferred by the users, many companies are adapting this fact and turning their offers into mobile version of communicating.',
        "In order to streamline deviations, corrective and preventive actions (CAPAs), and other processes, the use of digital tools like separate software is used to perform these tasks. Digital tools built on the same platform provide further visibility into a company's quality assurance.",
        "Our company's Tech Support Officers are willing to assist and resolve your company's technical issues, provide timely customer feedback, support the roll-out of new applications, talk to customers directly, as well as create written documentation, with excellent written and verbal communication.",
      ]
    : [
        'Stetige Veränderungen in unserer modernen Welt erzwingen die Automatisierung vieler Kommunikations- Kooperationsprozesse. Online-Bestellungen und -Bezahlungen, Produkt-Verteilung und -Versand bis hin zur Produktion von Gütern erfordern eine konsequente Digitalisierung, um mit der Geschwindigkeit von Veränderungenmithalten zu können.',
        'Digitalisierung bedeutet nicht nur Transformation objektiver Prozesse sondern zunehmend auch subjektive Geschäftsprozesse, welche eng mit Empfindungen wie Sehen, Fühlen, Glauben, Vertrauen verknüpft sind. Marken, die in einem stark objektiven Umfeld entstanden und erfolgreich geworden sind, wandeln sich heute zunehmend in eine online-gestützte Markenwahrnehmung mit transparenten Kundenbewertungen und empfehlungen.',
        'Maßgeschneiderte Lösungen zur Vernetzung komplexer Einzelvorgänge in Ihrem Unternehmen, wie z.B. Team Work, Projektmanagement, Beschaffung.',
        'Bis zu 90% unserer Zeit verbringen wir mit Arbeiten auf dem Smartphone. Gerade deshalb sind mobile App-Anwendungen die beliebteste Form der Digitalisierung von Geschäftsprozessen.',
        'Der Ausgleich von Abweichungen, Korrekturen und vorbeugende Maßnahmenzur Qualitätsabsicherung interner Geschäftsprozesse und Produktionsabläufe bieten ein weites Feld für digitalisierte Prozessschritte.',
        "Our company's Tech Support Officers are willing to assist and resolve your company's technical issues, provide timely customer feedback, support the roll-out of new applications, talk to customers directly, as well as create written documentation, with excellent written and verbal communication.",
      ]
export let benefits_12 =
  language === 'en'
    ? [
        'Paid Holidays',
        'Paid sick days',
        'Casual dress',
        'Free Coffee',
        'Some meals provided',
        'Company Outings',
        'Stocked kitchen',
        'Job Training & Conferences',
        'Lunch & Learns',
        'Game Room',
        'Recreational clubs',
        'Diversity Program',
      ]
    : [
        'Bezahlte Feier- und Urlaubstage',
        'Bezahlte Krankheitstage',
        'dress code: lässig und leger',
        'kostenloser Kaffee',
        'für Verpflegung wird gesorgt',
        'Betriebsausflüge',
        'eine gut befüllte Küche',
        'Weiterbildungsprogramme & Konferenzen',
        'Mittagessen & Lernen',
        'Chill-Area mit unterschiedlichen Spielen',
        'Freizeitclubs',
        'Programme zur Förderung der Diversität',
      ]
export let placeholders_4 =
  language === 'en'
    ? ['First Name', 'Last Name', 'Email', 'Message', 'Subject', 'Full Name']
    : ['Vorname', 'Name', 'E-Mail', 'Nachricht', 'Subject', 'Full Name']
