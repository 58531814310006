import React, {Component} from 'react';
import SimpleReactValidator from "simple-react-validator";

class Form extends Component {
    validator = new SimpleReactValidator({
        element: message => <label className={"error-message"} style={{color:'red'}}>{message}</label>
    });

    handleChangeDate = (date,name) => {
        const data = {...this.state.data};
        data[name] = date.format("DD/MM/YYYY hh:mm A");
        this.setState({
            data
        })
    };

    handleChange = ({currentTarget: input}) => {
        const data = {...this.state.data};
        if (input.type === 'checkbox') {
            data[input.name] = !data[input.name];
        } else if (input.type === 'file') {
            data[input.name] = input.files[0];
        } else {
            data[input.name] = input.value;
        }
        this.setState({
            data
        })
    };

    handleSubmit = e => {
        e.preventDefault();
        this.validator.showMessages();
        this.forceUpdate();
        if (this.validator.allValid()) {
            this.submitForm();
        }
    };

    submitForm() {
        throw new TypeError("Implemento submitin majmun");
    }
}

export default Form;