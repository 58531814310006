import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Form from '../../common/form/form'
import Input from '../../common/form/input'
import TextArea from '../../common/form/textarea'
import apiService from '../../services/apiService'
import { toast } from 'react-toastify'
import lines from '../../assets/img/lines.svg'
import { FaFacebookF, FaLinkedinIn, FaInstagram } from 'react-icons/fa'
import { withTranslation } from 'react-i18next'

import {
  footer_title,
  header_titles,
  contact_button_text,
  placeholders_4,
} from '../../locales/translation'
import { connect } from 'react-redux'

class Footer extends Form {
  constructor(props) {
    super(props)
    this.state = {
      data: {
        first_name: '',
        last_name: '',
        email: '',
        message: '',
        blogs: [],
      },
    }
  }

  componentDidMount() {
    if (this.props.language) {
      apiService.getBlogs(this.props.language).then(({ data: response }) => {
        this.setState({
          blogs: response.slice(0, 2),
        })
      })
    }
  }

  submitForm() {
    const { data } = this.state
    apiService.postContactUs(data).then(() => {
      toast.success(
        'Thank you for contacting us. We will contact you as soon as possible!',
      )
      this.setState({
        data: {
          first_name: '',
          last_name: '',
          email: '',
          message: '',
        },
      })
      this.validator.hideMessages()
      this.forceUpdate()
    })
  }

  handleNav(e) {
    if (e === undefined && this.props.location.pathname === '/') {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    if (e && e.target.getAttribute('href') === this.props.location.pathname) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  render() {
    let year = new Date().getFullYear()
    let { t } = this.props

    const { data } = this.state
    const { first_name, last_name, email, message } = data

    return (
      <React.Fragment>
        <div
          style={{ display: window.location.pathname === '/contact' && 'none' }}
          className={`footer`}
          id={'footer'}
        >
          <div className={`first-container`}>
            <div className={`second-container extra-container`}>
              <div className={`container`}>
                <div className={`footer-logo pleft15`}>
                  <img src="./assets/img/logo.svg" alt="" />
                  <img className="side-lines" src={lines} alt={'logo lines'} />
                </div>
                <div className={`grid footer-content pleft15`}>
                  <div className={`footer-content_ first-part grid-2 grid-1`}>
                    <span className={`title`}>{t('footerTitle')}</span>
                    <Link
                      onClick={(e) => {
                        document.body.classList.remove('white-menu')
                        document
                          .getElementById('navActiveRem')
                          .classList.remove('active')
                        this.handleNav()
                      }}
                      to="/"
                    >
                      {t('home')}
                    </Link>
                    <Link onClick={(e) => this.handleNav(e)} to="/aboutus">
                      {t('company')}
                    </Link>
                    <Link onClick={(e) => this.handleNav(e)} to="/works">
                      {t('works')}
                    </Link>
                    <Link onClick={(e) => this.handleNav(e)} to="/blogs">
                      {t('blog')}
                    </Link>
                    <Link onClick={(e) => this.handleNav(e)} to="/career">
                      {t('capitalCareer')}
                    </Link>
                    <div className={`copyright mobileHide`}>
                      <span>© Copyright {year} TECH FRAME</span>
                    </div>
                  </div>
                  <div className={`second-part grid-2`}>
                    <div className={`footer-content_ contact`}>
                      <form
                        className={`contact-form`}
                        onSubmit={this.handleSubmit}
                      >
                        <div className={`username`}>
                          <Input
                            onChange={this.handleChange}
                            name={'first_name'}
                            type={'text'}
                            id={'first_name'}
                            placeholder={t('firstName')}
                            value={first_name}
                            validator={this.validator}
                            validations={'required|min:3'}
                            className={'name'}
                          />
                          <Input
                            onChange={this.handleChange}
                            name={'last_name'}
                            type={'text'}
                            id={'last_name'}
                            placeholder={t('lastName')}
                            value={last_name}
                            validator={this.validator}
                            validations={'required|min:3'}
                            className={'last_name'}
                          />
                        </div>
                        <div className={`email`}>
                          <Input
                            onChange={this.handleChange}
                            name={'email'}
                            type={'text'}
                            id={'email'}
                            placeholder={t('email')}
                            value={email}
                            validator={this.validator}
                            validations={'required|email|min:3'}
                          />
                        </div>
                        <div className={`description`}>
                          <TextArea
                            onChange={this.handleChange}
                            name={'message'}
                            id={'message'}
                            placeholder={t('message')}
                            value={message}
                            validator={this.validator}
                            validations={'required|min:10'}
                          />
                        </div>
                        <div className={`social button`}>
                          <div className={`social_`}>
                            <a
                              href="https://www.facebook.com/tframelabs"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaFacebookF className="footerSocialIcon" />
                            </a>
                            <a
                              href="https://www.instagram.com/techframelabs"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaInstagram className="footerSocialIcon" />
                            </a>
                            <a
                              href="https://www.linkedin.com/company/techframelabs"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaLinkedinIn className="footerSocialIcon" />
                            </a>
                          </div>
                          <button
                            className={`submission-btn remove_margin hoverUp up`}
                            type="submit"
                          >
                            {t('sendMessage')}
                            <div className={`helper-div`}></div>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className={`copyright mobileShow`}>
                    <span>© Copyright {year} TECH FRAME</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
const mapStateToProps = (state) => {
  return { language: state.language.language }
}

export default (connect(mapStateToProps, null),
withRouter(withTranslation()(Footer)))
