import React from 'react';

const File = ({name = "", placeholder = "", onChange = null, id = "", value = "", multiple = false, disabled = false, validator = null, validations = "", allowedTypes = "img", filename = "", extradiv}) => {
    if (allowedTypes === "img")
        allowedTypes = "image/*";
    else
        allowedTypes = "application/msword,application/vnd.ms-powerpoint,text/plain, application/pdf";
        
    return (
        <React.Fragment>
            <div className={`popup-upload`}>
                <input placeholder={placeholder} onChange={onChange} value={value} disabled={disabled} name={name}
                       id={id} accept={allowedTypes}
                       type={'file'} multiple={multiple}/>
                <label htmlFor={id}>
                    <div>
                        {filename ? filename : placeholder}
                    </div>
                </label>
                <div className='additional-div'>{extradiv}</div>
                {validator.message(name, value, validations)}
            </div>
        </React.Fragment>
    );
};

export default File;