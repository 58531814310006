import React from 'react'
import apiService from '../../services/apiService'
import { withRouter } from 'react-router-dom'
import Input from '../../common/form/input'
import Form from '../../common/form/form'
import File from '../../common/form/file'
import Loader from '../loader/loader'
import ClientBox from '../home/clientBox'
import { connect } from 'react-redux'
import {
  works_title,
  // works_description1,
  works_description2,
  contact_button_text,
  works_description2Mobile,
} from '../../locales/translation'
import lines from '../../assets/img/lines.svg'
import { withTranslation } from 'react-i18next'

class Projects extends Form {
  constructor(props) {
    super(props)
    this.state = {
      projects: [],
      activePopup: false,
      project: {},
      loading: true,
      data: {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        image: [],
        job_id: '',
      },
    }
  }

  componentDidMount() {
    const currentLanguage = this.props.language
    document.body.classList.add('white-menu')
    if (currentLanguage) {
      apiService.getProjects(currentLanguage).then(({ data: response }) => {
        this.setState({
          projects: response,
          loading: false,
        })
        const scrollPos = sessionStorage.getItem('scrollPos')
        if (scrollPos) {
          window.scroll({
            top: scrollPos,
            behavior: 'smooth',
          })
        }
      })
    }
  }

  componentWillUnmount() {
    document.body.classList.remove('white-menu')
  }

  toggleApply = () => {
    const { onApply } = this.state
    this.setState({
      onApply: !onApply,
    })
    if (!onApply) {
      document.documentElement.style.overflow = 'hidden'
    } else {
      document.documentElement.style.overflow = 'auto'
    }
  }

  onRemove = () => {
    this.setState({
      activePopup: false,
    })
    document.documentElement.style.overflow = 'auto'
  }

  render() {
    const { onApply, projects, data, loading } = this.state
    const { first_name, last_name, email, phone, image } = data
    const { t } = this.props

    return (
      <React.Fragment>
        <div className="lines-bg"></div>
        <Loader loading={loading} />
        <div className={`projects`}>
          <div className={`form-apply`} is-active={`${!!onApply}`}>
            <div className={`form-apply_`}>
              <span className={`x`} onClick={this.toggleApply}>
                <span></span>
                <span></span>
              </span>
              <div className={`form-apply__`}>
                <span className={`inside-career-title`}>Apply Now</span>
                <form className={`contact-form`} onSubmit={this.handleSubmit}>
                  <div className={`username`}>
                    <Input
                      onChange={this.handleChange}
                      name={'first_name'}
                      type={'text'}
                      id={'first_name'}
                      placeholder={'First Name'}
                      value={first_name}
                      validator={this.validator}
                      validations={'required|min:3|max:20'}
                      className={'name popup-input'}
                    />
                    <Input
                      onChange={this.handleChange}
                      name={'last_name'}
                      type={'text'}
                      id={'last_name'}
                      placeholder={'Last Name'}
                      value={last_name}
                      validator={this.validator}
                      validations={'required|min:3|max:20'}
                      className={'popup-input last_name'}
                    />
                  </div>
                  <div className={`email`}>
                    <Input
                      onChange={this.handleChange}
                      name={'email'}
                      type={'text'}
                      id={'email'}
                      placeholder={'Email'}
                      value={email}
                      validator={this.validator}
                      validations={'required|email'}
                      className={'popup-input'}
                    />
                  </div>
                  <div className={`email`}>
                    <Input
                      onChange={this.handleChange}
                      name={'phone'}
                      type={'text'}
                      id={'phone'}
                      placeholder={'Phone (example: +383xxxxxxxx)'}
                      value={phone}
                      validator={this.validator}
                      validations={'required|phone'}
                      className={'popup-input'}
                    />
                  </div>
                  <div className={`description`}>
                    <File
                      onChange={this.handleChange}
                      name={'image'}
                      multiple={true}
                      id={'image'}
                      placeholder={'Click here to upload CV'}
                      value={image.filename}
                      filename={image.name}
                      validator={this.validator}
                      validations={'alpha_space'}
                      allowedTypes={'doc'}
                    />
                  </div>
                  <div className={`apply-now`}>
                    <button
                      className={`tf_button primary remove_margin`}
                      type="submit"
                    >
                      {t('sendMessage')}
                      <div className={`helper-div`}></div>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className={`first-container`}>
            <div className={`second-container extra-container`}>
              <div className={`container noPaddingProjects`}>
                <div className={`projects-content projects_page`}>
                  <div
                    style={{ alignItems: 'center', display: 'flex' }}
                    className={`big-title  color projects padding15Horizontal`}
                  >
                    <img src={lines} alt="" />
                    <span className="h2Tags">{t('capitalProjects')}</span>
                  </div>
                  <div className={`grid padding15Horizontal`}>
                    <div className={`grid-1 grid-1 inside_grid`}>
                      <div className={`inside_grid__`}>
                        <span className="spanProjects notMobileProjectsText">
                          {t('worksDescription2')}
                        </span>
                        <span className="spanProjects mobileProjectsText">
                          {t('worksDescription2Mobile')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="projects-wrapper">
                    <div
                      style={{ marginBottom: 15 }}
                      className="grid projects-container projects-container-big grid-container left-aligned"
                    >
                      {projects.length > 0 &&
                        projects.reverse().map((project, index) => (
                          <div
                            key={index}
                            className="grid-2 grid-1 project-item-wrapper"
                          >
                            <div className="project-item">
                              <div
                                className="project-image"
                                style={{
                                  backgroundImage: `url(${project.image})`,
                                }}
                              >
                                <div
                                  onClick={() =>
                                    this.props.history.push(
                                      `/work/${project.title}`,
                                    )
                                  }
                                  className="projectHoverLayer"
                                >
                                  <div className="hoverFillDiv">
                                    <h1 className="projectHoverText">
                                      {this.props.language === 'en'
                                        ? project.title
                                        : project.de_title}
                                    </h1>
                                    <div className="fillDivBlue"></div>
                                    <div className="underLineProject"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      {/* <Link className="see-more-btn hoverUp up" to="#">
                        Load More
                      </Link> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <ClientBox />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { language: state.language.language }
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, null)(Projects)),
)
