import React, { Component } from 'react'
import lines from '../../assets/img/lines.svg'
import '../../assets/css/aboutUs.css'
import Cards5 from './aboutUsSection2/cards5'
import BigSquare from './aboutUsSection2/bigSquare'
import {
  company_first_section_title,
  company_first_section_description,
  company_second_section_title,
  company_third_section_title,
} from '../../locales/translation'
// import KeyMemberCard from "./aboutUsSection2/keyMemberCard";
import { withTranslation } from 'react-i18next'
import { t } from 'i18next'

class SecondSection extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    if (this.props.currentPage === 'Home') {
      document.getElementById('video').setAttribute('autoplay', true)
    }
  }

  scroll = () => {
    document.getElementById('footer').scrollIntoView()
  }

  render() {
    const { currentPage } = this.props
    return (
      <React.Fragment>
        <div
          className={`extra-container ${
            currentPage === 'Home' ? 'container-fluid' : ''
          } `}
        >
          <div
            style={{
              paddingTop: 0,
            }}
            className={`second-section`}
          >
            <div style={{ paddingTop: 50 }} className="aboutusContainerSecond">
              <div style={{ marginBottom: 50 }} className="whoWeAreDiv">
                <div style={{ marginBottom: 20 }} className="whoWeAreDivTitle">
                  <img alt="linesMobile" className="linesMobile" src={lines} />
                  <div>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        fontSize: 30,
                        color: '#000347',
                      }}
                    >
                      {t('who')}{' '}
                    </h2>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        fontSize: 30,
                        color: '#000347',
                      }}
                    >
                      {t('weAre')}
                    </h2>
                  </div>
                </div>
                <div className="whoWeAreDivText">
                  <div className="whoWeAreDivText1">
                    <p
                      className="ptags"
                      style={{
                        lineHeight: '24px',
                        fontSize: '18px',
                        color: '#2D2D40',
                      }}
                    >
                      {t('companyFirstSectionDescription0')}
                    </p>
                  </div>
                  <div className="whoWeAreDivText2">
                    <p
                      className="ptags marginBSmaller"
                      style={{
                        lineHeight: '24px',
                        fontSize: '18px',
                        color: '#2D2D40',
                      }}
                    >
                      {t('companyFirstSectionDescription1')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div style={{paddingTop:0}} className="aboutusContainerSecond">
              <div className="container">
                <div  className="keyMembersTitle">
                  <img alt="linesMobile" className="linesMobile" src={lines} />
                  <div>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        fontSize: 30,
                        color: "#000347",
                      }}
                    >
                      KEY{" "}
                    </h2>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        fontSize: 30,
                        color: "#000347",
                      }}
                    >
                      MEMBERS
                    </h2>
                  </div>
                </div>
                <KeyMemberCard/>
              </div>
            </div> */}
            <div className="aboutusContainerSecondBox">
              <div className="whoAreWeDivBoxes">
                <div
                  style={{
                    marginBottom: 0,
                  }}
                  className="whoWeAreDivTitle padleftCom"
                >
                  <img alt="linesMobile" className="linesMobile" src={lines} />
                  <div>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        color: '#000347',
                      }}
                    >
                      {t('our')}{' '}
                    </h2>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        color: '#000347',
                      }}
                    >
                      {t('services')}
                    </h2>
                  </div>
                </div>
                <Cards5 />
                <div className="whoWeAreDivTitle padleftCom">
                  <img alt="linesMobile" className="linesMobile" src={lines} />
                  <div>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        color: '#000347',
                      }}
                    >
                      {t('industry')}
                    </h2>
                    <h2
                      className="h2Tags"
                      style={{
                        marginLeft: 10,
                        lineHeight: 1.4,
                        color: '#000347',
                      }}
                    >
                      {t('expertise')}
                    </h2>
                  </div>
                </div>
                {/* <div style={{paddingLeft:33,paddingRight:33}}> */}
                <BigSquare />
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export default withTranslation()(SecondSection)
