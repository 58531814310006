import React, { Component } from 'react'
import image from '../../assets/img/techFramePics/Company-1.jpg'
// import {
//   company_banner_title,
//   company_banner_description,
//   // company_banner_button_text,
// } from '../../locales/translation'
import RenderSmoothImage from 'render-smooth-image-react'
import { t } from 'i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

class FirstSection extends Component {
  render() {
    const languageVal = this.props.languageVal

    return (
      <React.Fragment>
        <div className="aboutUsNew">
          <div className="aboutusContainer">
            <div className="aboutUsNewLeft">
              <div className="aboutUsNewRight">
                {/* <img
                  alt="rightaboutimage"
                  src={image}
                /> */}
                <div className="rightaboutimage">
                  {' '}
                  <RenderSmoothImage src={image} alt="alternate-text" />
                </div>
                <div className="companyImageLayer" />
              </div>
              <div className="aboutUsNewLeftContainer">
                <div className="aboutUsNewLeftContainerText">
                  <h2
                    className={
                      languageVal === 'de' ? 'h2Tags deText' : 'h2Tags'
                    }
                    style={{
                      marginBottom: '20px',
                      lineHeight: 1.5,
                      textTransform: 'uppercase',
                      color: '#000347',
                      fontSize: 45,
                    }}
                  >
                    {t('becomeATrue')}{' '}
                    <span
                      style={{
                        color: '#00A9FC',
                        fontFamily: 'black-font',
                        fontSize: 45,
                      }}
                    >
                      {t('inovation')}
                    </span>{' '}
                    {t('partnerToOurClients')}
                  </h2>
                </div>
                <div className="text2company">
                  <p
                    className="headPTag marginBSmaller"
                    style={{
                      marginBottom: '50px',
                      lineHeight: '24px',
                      color: '#2D2D40',
                      fontSize: '18px',
                      position: 'relative',
                      zIndex: 10,
                    }}
                  >
                    {t('companyBannerDescription')}
                  </p>
                  {/* <a
                  className='hoverUp up'
                  id="companyPresentationBtn"
                  style={{
                    textDecoration: "none",
                    fontSize: 18,
                    margin: 0,
                    padding: 13,
                    paddingRight: 25,
                    paddingLeft: 25,
                    outline: "none",
                    border: "1px solid grey",
                    color: "#000347",
                    width: "fit-content",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  href="/tframe.pdf"
                  target="_blank"
                >
                  <p>{company_banner_button_text}</p>
                </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { languageVal: state.language.language }
}

export default connect(mapStateToProps, null)(withRouter(FirstSection))
