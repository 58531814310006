import React, { useState } from 'react'
import { Collapse } from '@material-ui/core'
import './language.css'
import { getLanguageState } from '../store/reducers/languageReducer'
import { useSelector, useDispatch } from 'react-redux'
import { changeLanguage } from '../store/actions/language'

export const ChangeLanguage = () => {
  const [showDropDown, setShowDropDown] = useState(false)
  const languageState = useSelector(getLanguageState)
  const dispatch = useDispatch()

  return (
    <React.Fragment>
      <div
        className={`languageDropDown `}
        onMouseEnter={() => setShowDropDown(true)}
        onMouseLeave={() => setShowDropDown(false)}
      >
        <div className="dropDownLang">
          <div className="dropDownItem">
            <span>{languageState.language === 'en' ? 'EN' : 'DE'}</span>
          </div>
          <Collapse in={showDropDown}>
            <div
              onClick={() => {
                if (languageState.language === 'en') {
                  dispatch(changeLanguage('de'))
                } else {
                  dispatch(changeLanguage('en'))
                }
                window.location.reload()
              }}
              className="dropDownItem"
            >
              <span>{languageState.language !== 'en' ? 'EN' : 'DE'}</span>
            </div>
          </Collapse>
        </div>
      </div>
    </React.Fragment>
  )
}

export const ChangeLanguageMobile = () => {
  const [showDropDown, setShowDropDown] = useState(false)
  const languageState = useSelector(getLanguageState)
  const dispatch = useDispatch()
  return (
    <React.Fragment>
      <div
        className={`mobileDropdown`}
        onClick={() => setShowDropDown(!showDropDown)}
      >
        <div className="dropDownLang">
          <div className="dropDownItem">
            <span>{languageState.language === 'en' ? 'EN' : 'DE'}</span>
          </div>
          <Collapse in={showDropDown}>
            <div
              onClick={() => {
                if (languageState.language === 'en') {
                  dispatch(changeLanguage('de'))
                } else {
                  dispatch(changeLanguage('en'))
                }
                window.location.reload()
              }}
              className="dropDownItem"
            >
              <span>{languageState.language !== 'en' ? 'EN' : 'DE'}</span>
            </div>
          </Collapse>
        </div>
      </div>
    </React.Fragment>
  )
}

export default {
  ChangeLanguage,
}
