import React, { Component } from 'react'
import OwlCarousel from 'react-owl-carousel'
import 'owl.carousel/dist/assets/owl.carousel.css'
import 'owl.carousel/dist/assets/owl.theme.default.css'
import { withRouter } from 'react-router-dom'
import apiService from '../../services/apiService'
import renderHTML from 'react-render-html'
import { Helmet } from 'react-helmet'
import Loader from '../loader/loader'
import './projectDetails.css'
import leftArrow from '../../assets/img/left-pro-arrow.svg'
import rightArrow from '../../assets/img/right-pro-arrow.svg'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import { connect } from 'react-redux'
import {
  inside_work_next,
  inside_work_prev,
  inside_work_details,
} from '../../locales/translation'
import { withTranslation } from 'react-i18next'

class InsideProject extends Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.match.params.title,
      project: {},
      testimonials: [],
      projects: [],
      loading: true,
    }
  }

  titleFunction = async () => {
    document.body.classList.add('white-menu')
    const { title } = this.state
    await apiService.getProjectByTitle(title).then(({ data: response }) => {
      this.setState({
        project: response,
        loading: false,
      })
    })
  }

  testimonialsFunction = async () => {
    if (this.props.language) {
      await apiService
        .getTestimonials(this.props.language)
        .then(({ data: response }) => {
          this.setState({
            testimonials: response,
          })
        })
    }
  }

  projectsFunction = async () => {
    if (this.props.language) {
      await apiService
        .getProjects(this.props.language)
        .then(({ data: response }) => {
          this.setState({
            projects: response,
          })
          const scrollPos = sessionStorage.getItem('scrollPos')
          if (scrollPos) {
            window.scroll({
              top: scrollPos,
              behavior: 'smooth',
            })
          }
        })
    }
  }

  componentDidMount() {
    this.titleFunction()
    this.testimonialsFunction()
    this.projectsFunction()
  }

  componentWillUnmount() {
    document.body.classList.remove('white-menu')
  }

  changeProject = (direction) => {
    let copiedProjects = [...this.state.projects]
    let foundIndex = copiedProjects.findIndex(
      (item) => item.title === this.state.title,
    )
    let newProjectTitle
    let arrLength = copiedProjects.length
    if (direction === 'next') {
      if (foundIndex === arrLength - 1) {
        newProjectTitle = copiedProjects[0].title
      } else {
        newProjectTitle = copiedProjects[foundIndex + 1].title
      }
    }
    if (direction === 'prev') {
      if (foundIndex === 0) {
        newProjectTitle = copiedProjects[arrLength - 1].title
      } else {
        newProjectTitle = copiedProjects[foundIndex - 1].title
      }
    }
    this.setState({
      title: newProjectTitle,
    })
    this.props.history.push(`/work/${newProjectTitle}`)
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.title !== this.props.match.params.title) {
      this.setState({
        loading: true,
      })
      this.titleFunction()
      this.testimonialsFunction()
      this.projectsFunction()
    }
  }

  render() {
    const { project, loading } = this.state
    const { t } = this.props
    return (
      <React.Fragment>
        <Helmet>
          <meta
            id="url"
            property="og:url"
            content={`https://tframe.de/work/${project[0]?.title}`}
          />
          <meta id="type" property="og:type" content="article" />
          <meta id="title" property="og:title" content={project[0]?.title} />
          <meta
            id="description"
            property="og:description"
            content={project[0]?.description}
          />
          <meta id="image" property="og:image" content={project[0]?.image} />
        </Helmet>
        <Loader loading={loading} />
        <div className="projects-bg"></div>
        <div className={`inside-project`}>
          <div className={`first-container`}>
            <div className={`second-container`}>
              <div className="container">
                <div className="grid">
                  <div className="grid-2 grid-1">
                    <div className="project-details-container">
                      <h1 className="h2Tags">
                        {this.props.language === 'en'
                          ? project[0]?.title
                          : project[0]?.de_title}
                      </h1>
                      <h6
                        style={{
                          fontFamily: 'ligh-font',
                          fontSize: '18px',
                          lineHeight: '22px',
                        }}
                      >
                        {this.props.language === 'en'
                          ? project[0]?.description
                          : project[0]?.de_description}
                      </h6>
                      {/* <p style={{ fontWeight: "bold", display: "flex" }}>
                        {t('date')}:
                        <div>
                          <span
                            style={{
                              fontFamily: "ligh-font",
                              color: "#2D2D40",
                              marginLeft: "10px",
                              fontWeight: "normal",
                            }}
                          >
                            {project[0]?.date_finished && project[0]?.de_title !== 'ShedWool'
                              ? project[0]?.date_finished.slice(0, 17)
                              : `${new Date().getFullYear()}-${
                              new Date().getMonth() >= 10
                                ? new Date().getMonth()
                                : `0${new Date().getMonth()}`
                              }-${
                              new Date().getDate() >= 10
                                ? new Date().getDate()
                                : `0${new Date().getDate()}`
                              }`}
                          </span>
                        </div>
                      </p> */}
                      {/* <p style={{ fontWeight: "bold", display: "flex" }}>
                        {t('client')}:{" "}
                        <div>
                          <span
                            style={{
                              fontFamily: "ligh-font",
                              marginLeft: "10px",
                              color: "#2D2D40",
                              fontWeight: "normal",
                            }}
                          >
                            {" "}
                            {project[0]?.testimonial_full_name
                              ? project[0]?.testimonial_full_name
                              : "Brian Newton"}
                          </span>
                        </div>
                      </p> */}
                      {/* <p
                        style={{
                          fontWeight: "bold",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {t('category')}:{" "}
                        <div style={{ display: "flex" }}>
                          {project[0]?.categories ? (
                            project[0]?.categories.map((item, index) => (
                              <div key={index} className="tagProject">
                                <span>{item.name}</span>
                              </div>
                            ))
                          ) : (
                              <React.Fragment>
                                <div className="tagProject">
                                  <span>
                                    {" "}
                                    {project[0]?.tags
                                      ? project[0]?.tags
                                      : "Web Design"}
                                  </span>
                                </div>
                                <div className="tagProject">
                                  <span>
                                    {" "}
                                    {project[0]?.tags
                                      ? project[0]?.tags
                                      : "Developement"}
                                  </span>
                                </div>
                              </React.Fragment>
                            )}
                        </div>
                      </p> */}
                    </div>
                  </div>
                  <div className="grid-2 grid-1">
                    <div className="project-image-box-container">
                      <div
                        style={{
                          backgroundColor: `${
                            project[0]?.color ? project[0]?.color : '#5C6DFB'
                          }`,
                        }}
                        className="little-box"
                      ></div>
                      {project[0]?.image && (
                        <div className="item-image">
                          <img src={`${project[0]?.image}`} alt="" />
                        </div>
                      )}
                      <div
                        style={{
                          backgroundColor: `${
                            project[0]?.color ? project[0]?.color : '#5C6DFB'
                          }`,
                        }}
                        className="big-box"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ maxWidth: 1100 }} className="container">
                <div className={`text-editor ql-editor project-inside`}>
                  {this.props.language === 'en'
                    ? renderHTML(`${project[0]?.content}`)
                    : renderHTML(`${project[0]?.de_content}`)}
                </div>
                <div style={{ maxWidth: 1100 }} className={`container`}>
                  {/* {project[0]?.testimonial_image && (
                    <OwlCarousel
                      className="owl-theme inside-project-carousel"
                      margin={1}
                      items={1}
                      autoplay={true}
                      dots={false}
                      nav={true}
                    >
                      <div className="item inside-project-item">
                        <div className="testimonial-item">
                          <img
                            src={
                              project[0]?.testimonial_image &&
                              project[0]?.testimonial_image
                            }
                            alt=""
                          />
                          {this.props.language === "en" ? (
                            <p
                              style={{
                                color: "#2D2D40",
                                fontFamily: "ligh-font",
                                fontSize: "18px",
                                lineHeight: "24px",
                              }}
                              className="testimonial-dscp"
                            >
                              ‘’
                              {project[0]?.testimonial_content &&
                                project[0]?.testimonial_content}
                              ’’
                            </p>
                          ) : (
                            <p
                              style={{
                                color: "#2D2D40",
                                fontFamily: "ligh-font",
                                fontSize: "18px",
                                lineHeight: "24px",
                              }}
                              className="testimonial-dscp"
                            >
                              ‘’
                              {project[0]?.testimonial_de_content &&
                                project[0]?.testimonial_de_content}
                              ’’
                            </p>
                          )}
                          <div>
                            {project[0]?.testimonial_full_name && (
                              <div
                                style={{
                                  display: "flex",
                                  marginTop: 30,
                                  alignItems: "center",
                                }}
                              >
                                <p
                                  style={{ margin: 0, color: "#00a9ff" }}
                                  className="full-name"
                                >
                                  {project[0]?.testimonial_full_name &&
                                    project[0]?.testimonial_full_name}
                                </p>
                                <a
                                  href={
                                    project[0]?.testimonial_linkedin_profile &&
                                    project[0]?.testimonial_linkedin_profile
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    marginLeft: 5,
                                    borderLeft: "1px solid grey",
                                    paddingLeft: 5,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <LinkedInIcon
                                    style={{ fontSize: 30, color: "#00a9ff" }}
                                  />
                                </a>
                              </div>
                            )}
                            <p
                              style={{ marginTop: 0, marginBottom: 20 }}
                              className="full-name"
                            >
                              {project[0]?.testimonial_position &&
                                project[0]?.testimonial_position}
                            </p>
                          </div>
                        </div>
                      </div>
                    </OwlCarousel>
                  )} */}
                  <div className="section-divider"></div>
                  <div className="inside-project-navigation">
                    <div
                      onClick={() => this.changeProject('prev')}
                      className="nav-left"
                    >
                      <span>
                        <img className="removeMobile" src={leftArrow} alt="" />{' '}
                        {t('insideWorkPrev')}
                      </span>
                    </div>
                    <div
                      onClick={() => this.changeProject('next')}
                      className="nav-right"
                    >
                      <span>
                        {t('insideWorkNext')}{' '}
                        <img className="removeMobile" src={rightArrow} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  return { language: state.language.language }
}

export default withRouter(
  withTranslation()(connect(mapStateToProps, null)(InsideProject)),
)
